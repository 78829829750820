import { Modal, Spin } from 'antd';
import { useFormik } from 'formik';
import React from 'react';
import { closeCircle } from '../../../../assets/svg';
import CustomInput from '../../../../Components/forms/Input';
import * as Yup from 'yup';
import { LoadingOutlined } from '@ant-design/icons';

const ConfluenceModal = ({ state, setState, user, createConfluence, showErrorMessage, showSuccessMessage, setDataLoading }) => {
	const onSubmit = async (values) => {
		try {
			await createConfluence({
				...values,
				userId: user.uid,
			});
			resetForm();
			setState(false);
			showSuccessMessage('confluence created successfully');
		} catch (err) {
			showErrorMessage(err);
		} finally {
			setDataLoading(true);
		}
	};

	const { handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm, isSubmitting } = useFormik({
		initialValues: {
			confluence: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			confluence: Yup.string().required().label('Confluence'),
		}),
		onSubmit,
	});
	return (
		<div>
			<Modal footer={false} closable={false} className="custom_modal" width="618px" open={state} onCancel={() => setState(false)}>
				<div className="settings_modal">
					<div className="top">
						<span>Add Confluence</span>
						<span className="cursor-pointer" onClick={() => setState(false)}>
							{closeCircle}
						</span>
					</div>
					<div className="forms">
						<CustomInput
							label="New Confluence"
							placeholder="Enter"
							onChange={handleChange('confluence')}
							errorVisible={touched['confluence']}
							errorMessage={errors['confluence']}
							onBlur={handleBlur('confluence')}
							value={values.confluence}
						/>
						<p style={{ fontSize: '16px' }}>Example Formats</p>
						<ul style={{ marginLeft: '1.4rem' }}>
							<li>trendline with atleast 2 touches</li>
							<li>Fibonacci level of 0.5 or 0.78</li>
							<li>Key support level on 4 hours TF</li>
						</ul>
					</div>
					{isSubmitting ? (
						<button className="button secondary">
							<Spin indicator={<LoadingOutlined />} style={{ fontSize: 8 }} spin />
						</button>
					) : (
						<button className="button secondary" onClick={() => handleSubmit()}>
							Save
						</button>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default ConfluenceModal;
