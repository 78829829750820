import { useState } from 'react';
import AppModal from '../appModal';
import ButtonComponent from '../Button/button';
import './styles.scss';
import { requestPayout } from '../../firebase/firebaseFunctions';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';

const RequestPayoutModal = ({ visible, onClose, userDetails }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { showErrorMessage, showSuccessMessage } = useNotificationContext();

	const submitPayoutRequest = async () => {
		setIsLoading(true);

		try {
			const res = await requestPayout({
				name: userDetails?.name,
				userId: userDetails?.userId,
				accountName: userDetails?.accountName,
				accountNumber: userDetails?.accountNumber,
				bankName: userDetails?.bankName,
			});
			showSuccessMessage('Payout requested successfully.');
			onClose();
		} catch (error) {
			showErrorMessage(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<AppModal width="509px" footer={false} closable={false} open={visible} title="Request Payout" onCancel={() => onClose()}>
			<div className="request_payout">
				<div className="payout_account_details">
					<div className="heading">
						<h4>Confirm Payout Account</h4>
					</div>
					<table>
						<tr>
							<td>Account Holder:</td>
							<td>{userDetails?.accountName}</td>
						</tr>
						<tr>
							<td>Account number:</td>
							<td>{userDetails?.accountNumber}</td>
						</tr>
						<tr>
							<td>Bank name:</td>
							<td>{userDetails?.bankName}</td>
						</tr>
					</table>
					<ButtonComponent buttonText="Submit" loading={isLoading} className="w-full button secondary mar-t-28" onClick={() => submitPayoutRequest()} />
				</div>
			</div>
		</AppModal>
	);
};

export default RequestPayoutModal;
