import InfoRateCard from '../../../Components/infoRateCard';
import { DatePicker, Select } from 'antd';
import './styles.scss';
import PerformanceProgress from '../../../Components/performanceProgress';
import AppLineChat from '../../../Components/lineChart';
import { useEffect, useMemo, useState } from 'react';
import { getDashboardData, getJournals, getPairSummary } from '../../../firebase/firebaseFunctions';
import { useAuthContext } from '../../../context/auth-context/AuthProvider';
import { getPercentageValue } from '../../../utils/helpers';
import dayjs from 'dayjs';
import SkeletonLoaderItem from '../../../Components/skeletonLoader';
const { RangePicker } = DatePicker;

const { Option } = Select;

const AnalyticsPage = () => {
	const { user } = useAuthContext();
	const [data, setData] = useState(null);
	const [dataLodaing, setDataLoading] = useState(false);
	const [journals, setJournals] = useState([]);
	const [journalsLoading, setJournalsLoading] = useState([]);
	const [topPerformance, setTopPerformance] = useState('Profit');
	let [pairSummaryLoading, setPairSummaryLoading] = useState(false);
	let [pairSummary, setPairSummary] = useState({});
	const [journalDataFilterObject, setJournalDataFilterObject] = useState({
		currencyPair: 'GBP/USD',
		timeStamp: null,
	});

	const getDashboardDataFunc = async () => {
		setDataLoading(true);
		try {
			const res = await getDashboardData(user?.uid);
			setData(res);
		} catch (error) {
			// console.log(error);
		} finally {
			setDataLoading(false);
		}
	};
	const getAllJournals = async () => {
		setJournalsLoading(true);
		try {
			const res = await getJournals(user?.uid);
			setJournals(res);
		} catch (error) {
			// console.log(error);
		} finally {
			setJournalsLoading(false);
		}
	};

	const journalAnalysisForPerformance = useMemo(() => {
		let profitCount = {};
		let lossCount = {};
		let totalProfit = 0;
		let totalLoss = 0;
		journals.forEach((e) => {
			if (e.tradeOutcome === 'Profit') {
				totalProfit++;
				profitCount[e.currencyPair] = !profitCount[e.currencyPair] ? 0 + 1 : profitCount[e.currencyPair] + 1;
			}
			if (e.tradeOutcome === 'Loss') {
				totalLoss++;
				lossCount[e.currencyPair] = !lossCount[e.currencyPair] ? 0 + 1 : lossCount[e.currencyPair] + 1;
			}
		});
		return {
			profitCount,
			lossCount,
			totalProfit,
			totalLoss,
		};
	}, [journals]);

	const topPerformanceFilltred = useMemo(() => {
		let arr = [];
		const { lossCount, profitCount, totalLoss, totalProfit } = journalAnalysisForPerformance;
		if (topPerformance === 'Profit') {
			arr = Object.keys(profitCount).map((key) => ({ currencyPair: key, percentage: getPercentageValue(profitCount[key], totalProfit) }));
		} else {
			arr = Object.keys(lossCount).map((key) => ({ currencyPair: key, percentage: getPercentageValue(lossCount[key], totalLoss) }));
		}
		return arr.sort((a, b) => b.percentage - a.percentage);
	}, [journals, topPerformance]);

	const rangePresets = [
		{ label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
		{ label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
		{ label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
		{ label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
	];

	const getPairSummaryFunc = async () => {
		try {
			setPairSummaryLoading(true);
			let res = await getPairSummary(user?.uid, journalDataFilterObject);
			setPairSummary(res);
		} catch (error) {
		} finally {
			setPairSummaryLoading(false);
		}
	};
	useEffect(() => {
		getDashboardDataFunc();
		getAllJournals();
	}, []);
	useEffect(() => {
		getPairSummaryFunc();
	}, [journalDataFilterObject]);
	return (
		<div className="analytics_page">
			<div className="top_cards">
				<InfoRateCard
					skLoading={dataLodaing}
					title="Trade Taken"
					percentage={`+${data?.total_trade_taken?.current_week} this week`}
					value={data?.total_trade_taken?.total || '0'}
					valueGrowth={true}
				/>
				<InfoRateCard
					skLoading={dataLodaing}
					title="Pair Traded"
					percentage={`+${data?.pair_traded?.current_week} this week`}
					value={data?.pair_traded?.total || '0'}
					valueGrowth={true}
				/>
				<InfoRateCard
					skLoading={dataLodaing}
					title="Profit"
					percentage={`+${data?.profitable_trades?.current_week} this week`}
					value={data?.profitable_trades?.total || '0'}
					valueGrowth={true}
				/>
				<InfoRateCard
					skLoading={dataLodaing}
					title="Loss"
					percentage={`+${data?.loss_trades?.current_week} this week`}
					value={data?.loss_trades?.total || '0'}
					valueGrowth={false}
				/>
				<InfoRateCard
					skLoading={dataLodaing}
					title="Breakeven"
					percentage={`+${data?.breakeven_trades?.current_week} this week`}
					value={data?.breakeven_trades?.total || '0'}
					valueGrowth={true}
				/>
			</div>

			<div className="chart_performance_wrapper">
				<div className="chart_wrapper">
					<div className="chart_top">
						<span className="title">Trading Summary</span>
						<Select value="1">
							<Option value="1">All</Option>
							<Option value="4">Today</Option>
							<Option value="2">Past week</Option>
							<Option value="3">Last Month</Option>
						</Select>
					</div>
					<AppLineChat />
				</div>
				<div className="performance_wrapper">
					<div className="top">
						<span>Top Perfomance</span>
						<Select value={topPerformance} onChange={(val) => setTopPerformance(val)}>
							<Option value="Profit">Profit</Option>
							<Option value="Loss">Loss</Option>
						</Select>
					</div>
					{journalsLoading ? (
						<div>
							<PerformanceProgress skLoading={journalsLoading} />
							<PerformanceProgress skLoading={journalsLoading} />
							<PerformanceProgress skLoading={journalsLoading} />
							<PerformanceProgress skLoading={journalsLoading} />
							<PerformanceProgress skLoading={journalsLoading} />
						</div>
					) : (
						<>
							{topPerformanceFilltred.length > 0 ? (
								<>
									{topPerformanceFilltred.map((j) => (
										<PerformanceProgress currencyPair={j?.currencyPair} percentage={Number(j.percentage)} growth={topPerformance === 'Profit'} />
									))}
								</>
							) : (
								<div className="empty_state">
									<span>No data</span>
								</div>
							)}
						</>
					)}
				</div>
			</div>
			<div className="pair_summary_wrapper">
				<div className="top">
					<span>Individual Pair Summary</span>
					<Select
						value={journalDataFilterObject?.currencyPair}
						style={{ width: '120px' }}
						onChange={(value) => {
							setJournalDataFilterObject((prev) => {
								return { ...prev, currencyPair: value };
							});
						}}
					>
						<Option value="GBP/USD">GBP/USD</Option>
						<Option value="XAU/USD">XAU/USD</Option>
						<Option value="US30">US30</Option>
						<Option value="NAS100">NAS100</Option>
					</Select>
					<RangePicker
						presets={rangePresets}
						onChange={(value) => {
							setJournalDataFilterObject((prev) => {
								return { ...prev, timeStamp: value };
							});
						}}
					/>
				</div>

				<div className="summary_card_wrapper">
					<PairSummaryCard skLoading={pairSummaryLoading} label="Trade Taken" value={pairSummary.total_trade_taken} />
					<PairSummaryCard skLoading={pairSummaryLoading} label="Profit" value={pairSummary.profitable_trades} />
					<PairSummaryCard skLoading={pairSummaryLoading} label="Loss" value={pairSummary.loss_trades} />
					<PairSummaryCard skLoading={pairSummaryLoading} label="Breakeven" value={pairSummary.breakeven_trades} />
				</div>
			</div>
		</div>
	);
};

export default AnalyticsPage;

export const PairSummaryCard = ({ label, value, skLoading }) => {
	return (
		<div className="summary_card">
			{!skLoading ? (
				<>
					<span>{label}</span>

					<div className="bottom">
						<span className="total_trade">{value}</span>
						{/* <span className="percentage growth">+20%</span> */}
					</div>
				</>
			) : (
				<div>
					<SkeletonLoaderItem className="mar-b-10" />
					<SkeletonLoaderItem className="mar-b-10" />
					<SkeletonLoaderItem />
				</div>
			)}
		</div>
	);
};
