import { arrowDown, arrowUp } from '../../assets/svg';
import { capitalizeFirstLetter } from '../../utils/helpers';
import SkeletonLoaderItem from '../skeletonLoader';
import './styles.scss';
const TradeCard = ({ orderType, currency, date, entryPrice, lotSize, skLoading, onClick }) => {
	return (
		<div className="trade_card" onClick={() => onClick && onClick()}>
			{skLoading ? (
				<SkeletonLoaderItem width="40px" height="40px" style={{ borderRadius: '50%' }} />
			) : (
				<>
					{orderType && (
						<div className={`order_type ${orderType.toLowerCase() || ''}`}>
							{orderType.toLowerCase() === 'buy' && arrowUp}
							{orderType.toLowerCase() === 'sell' && arrowDown}
						</div>
					)}
				</>
			)}
			<div className="details">
				{skLoading ? (
					<>
						<SkeletonLoaderItem width="70px" />
						<SkeletonLoaderItem width="30px" />
					</>
				) : (
					<>
						{orderType && (
							<span>
								{capitalizeFirstLetter(orderType)} {currency}
							</span>
						)}
						{date && <span>{date}</span>}
					</>
				)}
			</div>
			<div className="aside">
				{!skLoading ? (
					<>
						{entryPrice && <span>EP: {entryPrice}</span>}
						{lotSize && <span>{lotSize} unit</span>}
					</>
				) : (
					<>
						<SkeletonLoaderItem width="60px" />
						<SkeletonLoaderItem width="30px" />
					</>
				)}
			</div>
		</div>
	);
};

export default TradeCard;
