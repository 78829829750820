import { useState, useEffect } from 'react';
import { addIcon, closeCircle } from '../../../assets/svg';
import ProjectionCard from '../../../Components/projectionCard';
import CustomInput from '../../../Components/forms/Input';
import Textarea from '../../../Components/forms/TextArea';
import DateInput from '../../../Components/forms/DateInput';
import { Modal, Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getProjections, createProjection, deleteProjection } from '../../../firebase/firebaseFunctions';
import './styles.scss';
import TimeInput from '../../../Components/forms/TimeInput';
import { useAuthContext } from '../../../context/auth-context/AuthProvider';
import { useNotificationContext } from '../../../context/notification-context/NotificationProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const ProjectionPage = () => {
	const [showAddNewModal, setShowAddNewModal] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	// const [isSubmitting, setSubmitting] = useState(false);
	const { showErrorMessage, showSuccessMessage } = useNotificationContext();
	const [projectionsData, setProjectionsData] = useState([]);
	const { user } = useAuthContext();

	//status is to track the status of the projection if it has happened or not.
	//(might be removed later though)
	const STATUS = {
		open: 'open',
		close: 'close',
	};

	const getProjectionsFunc = async () => {
		setDataLoading(true);
		try {
			const data = await getProjections(user?.uid);
			setProjectionsData(data);
		} catch (err) {
			showErrorMessage(err?.message);
		} finally {
			setDataLoading(false);
		}
	};

	const deleteProjectionFunc = async (projectionId) => {
		try {
			await deleteProjection(projectionId);
			showSuccessMessage('Projection Deleted!');
			setProjectionsData((prev) => prev.filter((p) => p.id !== projectionId));
		} catch (err) {
			showErrorMessage(err?.message);
		}
	};

	useEffect(() => {
		getProjectionsFunc();
	}, []);

	const onSubmit = async (values) => {
		try {
			let res = await createProjection({
				...values,
				userId: user.uid,
				status: STATUS.open,
			});
			projectionsData.push({
				...values,
				userId: user.uid,
				status: STATUS.open,
				id: res,
			});
			setShowAddNewModal(false);
			resetForm();
			showSuccessMessage('projection created successfully');
		} catch (err) {
			showErrorMessage(err);
		}
	};

	const { handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm, setFieldValue, isSubmitting } = useFormik({
		initialValues: {
			subject: '',
			projection: '',
			reminderDate: '',
			reminderTime: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			subject: Yup.string().required().label('Subject'),
			projection: Yup.string().required().label('Projection'),
			reminderDate: Yup.string().required().label('Date'),
			reminderTime: Yup.string().required().label('Time'),
		}),
		onSubmit,
	});

	return (
		<>
			<div className="projection_page">
				{/* <div className="top">
          <span className="page_heading">Projection Box</span>
          <button
            className="button primary"
            onClick={() => setShowAddNewModal(true)}
          >
            {addIcon}
            <span>Add new</span>
          </button>
        </div> */}

				{/* <div className="projections_wrapper">
          {dataLoading ? (
            <>
              <ProjectionCard skLoading={dataLoading} />
              <ProjectionCard skLoading={dataLoading} />
              <ProjectionCard skLoading={dataLoading} />
              <ProjectionCard skLoading={dataLoading} />
              <ProjectionCard skLoading={dataLoading} />
            </>
          ) : (
            <>
              {Array.isArray(projectionsData) && projectionsData.length >= 1 ? (
                <>
                  {projectionsData.map(
                    ({
                      subject,
                      projection,
                      reminderDate,
                      reminderTime,
                      id,
                    }) => (
                      <ProjectionCard
                        title={subject}
                        description={projection}
                        date={`${reminderDate} | ${reminderTime}`}
                        onDelete={() => deleteProjectionFunc(id)}
                      />
                    )
                  )}
                </>
              ) : (
                <div style={{ margin: "0 auto" }}>
                  <Empty />
                  <p style={{marginTop: "20px", color: "grey"}}>
                    Write a projection and we'll remind you of it in the due
                    date.
                  </p>
                </div>
              )}
            </>
          )}
        </div> */}
				<div className="projections_wrapper coming_soon">
					<h1>Coming Soon</h1>
				</div>
			</div>
			<Modal footer={false} closable={false} className="custom_modal" width="718px" open={showAddNewModal} onCancel={() => setShowAddNewModal(false)}>
				<div className="add_projection_modal">
					<div className="top">
						<span>Add Projection</span>
						<span className="cursor-pointer" onClick={() => setShowAddNewModal(false)}>
							{closeCircle}
						</span>
					</div>

					<CustomInput
						label="Subject"
						placeholder="Enter subject"
						className="mb-16"
						onChange={handleChange('subject')}
						errorVisible={touched['subject']}
						errorMessage={errors['subject']}
						onBlur={handleBlur('subject')}
						value={values.subject}
					/>
					<Textarea
						label="Projection"
						placeholder="Enter Projection"
						className="mb-16"
						onChange={handleChange('projection')}
						errorVisible={touched['projection']}
						errorMessage={errors['projection']}
						onBlur={handleBlur('projection')}
						value={values.projection}
					/>
					<div className="date_time">
						<DateInput
							label="Date"
							placeholder="Select Date"
							onChange={(date) => {
								setFieldValue('reminderDate', date);
							}}
							errorVisible={touched['reminderDate']}
							errorMessage={errors['reminderDate']}
							onBlur={handleBlur('reminderDate')}
							value={values.reminderDate}
						/>
						<TimeInput
							label="Time"
							placeholder="Select Time"
							onChange={(timeString) => {
								setFieldValue('reminderTime', timeString);
							}}
							errorVisible={touched['reminderTime']}
							errorMessage={errors['reminderTime']}
							onBlur={handleBlur('reminderTime')}
							value={values.reminderTime}
						/>
					</div>

					{isSubmitting ? (
						<button className="button secondary">
							<Spin indicator={<LoadingOutlined />} style={{ fontSize: 8 }} spin />
						</button>
					) : (
						<button className="button secondary" onClick={() => handleSubmit()}>
							Add Projection
						</button>
					)}
				</div>
			</Modal>
		</>
	);
};

export default ProjectionPage;
