import { EXAMPLE, TOGGLETHEME, TOGGLESIDEBAROPEN, TOGGLEAPPLOADING, SETPAYMENTMODAL } from "./types";

const GeneralReducer = (prevState, { type, payload }) => {
  switch (type) {
    case EXAMPLE:
      return {
        ...prevState,
        testExxampleString: payload,
      };
    case TOGGLEAPPLOADING: {
      return {
        ...prevState,
        appLoading: payload,
      }
    }
    case SETPAYMENTMODAL: {
      return {
        ...prevState,
        paymentModalVisible: payload,
      }
    }
    case TOGGLESIDEBAROPEN: {
      localStorage.setItem("sidebarOpen", payload);
      return {
        ...prevState,
        sidebarOpen: payload,
      }
    }
    case TOGGLETHEME: {
      localStorage.setItem("appDarkTheme", payload);
      if (payload) {
        document.documentElement.setAttribute("data-theme", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
      }
      return {
        ...prevState,
        isDarkModeTheme: payload,
      };
    }
    default:
      return prevState;
  }
};

export default GeneralReducer;
