import SkeletonLoaderItem from '../skeletonLoader';
import './styles.scss';
const RecomendationCard = ({ title, skLoading, onVisit }) => {
	return (
		<>
			{skLoading ? (
				<SkeletonLoaderItem width="100%" />
			) : (
				<div className="recomemdedation_card">
					<div className="no_image" />
					<span>{title}</span>
					<div className="aside">
						<button className="button secondary" onClick={onVisit}>
							Visit
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default RecomendationCard;
