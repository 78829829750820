import SkeletonLoaderItem from '../skeletonLoader';
import './styles.scss';
import { Progress } from 'antd';
const PerformanceProgress = ({ currencyPair, percentage, growth = true, skLoading }) => {
	if (skLoading) {
		return (
			<div className="performance_progress">
				<SkeletonLoaderItem width="100%" height="15px" />
			</div>
		);
	}
	return (
		<div className="performance_progress">
			{currencyPair && <span className="currency">{currencyPair}</span>}
			{percentage && (
				<>
					<Progress percent={percentage} showInfo={false} size="small" strokeColor="#E9B872" />
					{growth && <span className="growth">+{percentage}%</span>}
					{!growth && <span className="loss">-{percentage}%</span>}
				</>
			)}
		</div>
	);
};

export default PerformanceProgress;
