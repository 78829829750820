import { Modal } from 'antd';
import { arrowLeft, closeCircle } from '../../assets/svg';
import './styles.scss';

const AppModal = ({ backBtn, backbtnClick, title, footer = false, closable = false, width = '414px', open, onCancel, children, className, style }) => {
	return (
		<Modal footer={footer} closable={closable} className={`${className || ''} custom_modal`} width={width} open={open} onCancel={onCancel} style={style}>
			<div className="top">
				{title && (
					<div className="title">
						{backBtn && <span onClick={backbtnClick}>{arrowLeft}</span>}
						<h4>{title}</h4>
					</div>
				)}
				<span className="cursor-pointer" onClick={onCancel}>
					{closeCircle}
				</span>
			</div>
			{children}
		</Modal>
	);
};

export default AppModal;
