import dayjs from 'dayjs';
import TradeStatus from '../Components/tradeStatus';
import { Link } from 'react-router-dom';

export const journalTableColum = (navigate) => [
	{
		title: () => <span>Currency Pair</span>,
		dataIndex: 'currencyPair',
		key: 'currencyPair',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Order Type</span>,
		dataIndex: 'orderType',
		key: 'orderType',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Entry Price</span>,
		dataIndex: 'entryPrice',
		key: 'entryPrice',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Stop Loss</span>,
		dataIndex: 'stopLoss',
		key: 'stopLoss',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Take Profit</span>,
		dataIndex: 'takeProfit',
		key: 'takeProfit',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Lot Size</span>,
		dataIndex: 'lotSize',
		key: 'lotSize',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Status</span>,
		dataIndex: 'status',
		key: 'status',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Outcome</span>,
		dataIndex: 'tradeOutcome',
		key: 'tradeOutcome',
		render: (val) => (
			<div className="status">
				<TradeStatus status={val} />
			</div>
		),
	},
	{
		title: () => <span>Action</span>,
		dataIndex: 'id',
		key: 'id',
		render: (val) => (
			<div className="action">
				<span className="close" onClick={() => navigate(`/app/journals/update/${val}`)}>
					Update
				</span>
				{/* <div className="view cursor-pointer" onClick={() => viewTrade()}>
					<span>View</span>
					{rightArrow}
				</div> */}
			</div>
		),
	},
];
export const trackerjournalTableColumn = (navigate) => [
	{
		title: () => <span>Currency Pair</span>,
		dataIndex: 'currencyPair',
		key: 'currencyPair',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Order Type</span>,
		dataIndex: 'orderType',
		key: 'orderType',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Status</span>,
		dataIndex: 'status',
		key: 'status',
		render: (val) => <span>{val || '-'}</span>,
	},
	{
		title: () => <span>Outcome</span>,
		dataIndex: 'tradeOutcome',
		key: 'tradeOutcome',
		render: (val) => (
			<div className="status">
				<TradeStatus status={val} />
			</div>
		),
	},
	{
		title: () => <span>Action</span>,
		dataIndex: 'id',
		key: 'id',
		render: (val) => (
			<div className="action">
				<span className="close" onClick={() => navigate(`/app/journals/update/${val}`)}>
					Update
				</span>
				{/* <div className="view cursor-pointer" onClick={() => viewTrade()}>
					<span>View</span>
					{rightArrow}
				</div> */}
			</div>
		),
	},
];
export const missedTradeTableColumn = [
	{
		title: () => <span>Day</span>,
		dataIndex: 'day',
		key: 'day',
		render: (val) => <span>{val}</span>,
	},
	{
		title: () => <span>Reason</span>,
		dataIndex: 'reasonMissed',
		key: 'reasonMissed',
		render: (val) => <span>{val}</span>,
	},
];
export const affiliateTableColumn = [
	{
		title: () => <span>Date</span>,
		dataIndex: 'createdAt',
		key: 'createdAt',
		render: (val) => <span>{dayjs(val?.toDate()).format('DD MMM. YYYY | hh:mmA')}</span>,
	},
	{
		title: () => <span>Referral name</span>,
		dataIndex: 'displayName',
		key: 'displayName',
		render: (val) => <span>{val}</span>,
	},
	{
		title: () => <span>Status</span>,
		dataIndex: 'paid',
		key: 'paid',
		render: (val) => <span>{val ? 'Pro user' : 'Free user'}</span>,
	},
	{
		title: () => <span>Subscription type</span>,
		dataIndex: 'billing',
		key: 'billing',
		render: (val) => <span>{val?.planName || '-'}</span>,
	},
	{
		title: () => <span>Earning (USD)</span>,
		dataIndex: 'referralName',
		key: 'referralName',
		render: (val) => <span>-</span>,
	},
];
export const adminUserTableColumn = [
	{
		title: () => <span>Name</span>,
		dataIndex: 'displayName',
		key: 'displayName',
		render: (val, obj) => (
			<div className="user_detail">
				<span>{val}</span>
				<span>{obj.email}</span>
			</div>
		),
	},
	{
		title: () => <span>User Type</span>,
		dataIndex: 'type',
		key: 'type',
		render: (val) => <span style={{ textTransform: 'capitalize' }}>{val || 'User'}</span>,
	},
	{
		title: () => <span>Status</span>,
		dataIndex: 'paid',
		key: 'paid',
		render: (val) => <span>{val ? 'Pro user' : 'Free user'}</span>,
	},
	{
		title: () => <span>Enroll Date</span>,
		dataIndex: 'createdAt',
		key: 'createdAt',
		render: (val) => <span>{dayjs(val?.toDate()).format('DD MMM. YYYY')}</span>,
	},
	{
		title: () => <span></span>,
		dataIndex: 'type',
		key: 'type',
		render: (val, obj) =>
			(val === 'affiliate' || val === 'admin') && (
				<Link className="cursor-pointer" to={`/app/affiliate?u=${obj?.userId}`}>
					View
				</Link>
			),
	},
];
