import { closeCircle } from '../../assets/svg';
import { Modal } from 'antd';
import './styles.scss';
import {
	FacebookShareButton,
	FacebookIcon,
	FacebookMessengerShareButton,
	FacebookMessengerIcon,
	TwitterShareButton,
	XIcon,
	TelegramShareButton,
	TelegramIcon,
	WhatsappShareButton,
	WhatsappIcon,
	LinkedinShareButton,
	LinkedinIcon,
} from 'react-share';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';

const ShareModal = ({ visible, onClose, shareUrl, title }) => {
	const { showSuccessMessage } = useNotificationContext();

	return (
		<Modal footer={false} closable={false} className="custom_modal" width="450px" open={visible} onCancel={() => onClose()}>
			<div className="share_modal">
				<div className="top">
					<h4>Share with your friends</h4>
					<span className="cursor-pointer" onClick={() => onClose()}>
						{closeCircle}
					</span>
				</div>

				<div className="share_buttons cursor-pointer">
					<div
						className="copy"
						onClick={() => {
							navigator.clipboard.writeText(shareUrl);
							showSuccessMessage('Link copied');
						}}
					>
						<FontAwesomeIcon icon={faCopy} size="lg" round color="white" />
					</div>
					<FacebookShareButton url={shareUrl} title={title} className="share_button">
						<FacebookIcon size={32} round />
					</FacebookShareButton>
					<FacebookMessengerShareButton url={shareUrl} title={title} className="share_button">
						<FacebookMessengerIcon size={32} round />
					</FacebookMessengerShareButton>
					<TwitterShareButton url={shareUrl} title={title} className="share_button">
						<XIcon size={32} round />
					</TwitterShareButton>
					<TelegramShareButton url={shareUrl} title={title} className="share_button">
						<TelegramIcon size={32} round />
					</TelegramShareButton>
					<WhatsappShareButton url={shareUrl} title={title} className="share_button">
						<WhatsappIcon size={32} round />
					</WhatsappShareButton>
					<LinkedinShareButton url={shareUrl} title={title} className="share_button">
						<LinkedinIcon size={32} round />
					</LinkedinShareButton>
				</div>
			</div>
		</Modal>
	);
};

export default ShareModal;
