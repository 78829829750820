import { chartGrowth, chartLoss } from '../../assets/svg';
import SkeletonLoaderItem from '../skeletonLoader/index';
import './styles.scss';
const InfoRateCard = ({ className, title, value, percentage, valueGrowth, skLoading }) => {
	if (!title) return <></>;
	return (
		<div className={`info_rate_card ${className || ''}`}>
			{skLoading ? <SkeletonLoaderItem maxWidth="100px" width="100%" /> : <span>{title}</span>}
			{(value || skLoading) && (
				<div className="bottom">
					{skLoading ? (
						<>
							<SkeletonLoaderItem maxWidth="30px" width="100%" />
							<SkeletonLoaderItem maxWidth="30px" width="100%" />
						</>
					) : (
						<>
							<div className="figure">
								{valueGrowth ? chartGrowth : chartLoss}
								<span>{value}</span>
							</div>
							{percentage && <span className={valueGrowth ? 'growth' : 'loss'}>{percentage}</span>}
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default InfoRateCard;
