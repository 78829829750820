import DashboardHeader from './dashboardHeader';
import DashboardSidebar from './dashboardSidebar';
import { useNavigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useGeneralContext } from '../../context';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import { addIcon } from '../../assets/svg';
import './styles.scss';
import { useEffect } from 'react';
import PaymentPlanModal from '../../Components/paymentPlanModal';
import { Alert } from 'antd';
import dayjs from 'dayjs';

const DashboardLayout = () => {
	const {
		state: { sidebarOpen, paymentModalVisible },
		setPaymentModalVisible,
	} = useGeneralContext();
	const { user, loading: userDataLoading, logOut } = useAuthContext();
	const [searchParams] = useSearchParams();
	const paymentModalOpen = searchParams.get('pm');
	const navigate = useNavigate();
	const location = useLocation();
	const { trackerID } = useParams();

	useEffect(() => {
		if (!userDataLoading && !user) {
			navigate({ pathname: '/signin', search: location.search });
		} else if (!userDataLoading && user && !user?.emailVerified) {
			logOut();
			navigate({ pathname: '/signin', search: location.search });
		}
		// eslint-disable-next-line
	}, [user, userDataLoading]);

	return (
		<>
			<div className="dashboard_layout">
				<DashboardHeader skLoading={userDataLoading} />
				<DashboardSidebar skLoading={userDataLoading} />
				<div className={`dashboard_content ${location.pathname === `/app/tracker/${trackerID}` ? 'pad-0' : ''} ${sidebarOpen ? 'open' : ''}`}>
					{!userDataLoading && user?.uid && (
						<>
							{user?.userOnFreeMode && !user?.paid && (
								<Alert
									message={
										<>
											<span>You're currently on a free trial mode. You're free trial ends on {dayjs(user?.freeModeEnds.toDate()).format('DD MMM. YYYY')}. </span>
											<span style={{ textDecoration: 'underline' }} className="cursor-pointer" onClick={() => setPaymentModalVisible(true)}>
												Subscribe Now.
											</span>
										</>
									}
									type="info"
									className="mar-b-24"
									closable
								/>
							)}
							<Outlet context={[userDataLoading]} />
						</>
					)}
					{location.pathname !== `/app/journals/add` && (
						<button className="button primary floating-journal-btn" onClick={() => navigate('/app/journals/add')}>
							{addIcon}
							<span>New Journal</span>
						</button>
					)}
				</div>
			</div>
			<PaymentPlanModal
				visible={paymentModalVisible || paymentModalOpen}
				onClose={() => {
					navigate(
						{
							pathname: location.pathname,
							search: '',
						},
						{ replace: true }
					);
					setPaymentModalVisible(false);
				}}
			/>
		</>
	);
};

export default DashboardLayout;
