import { NavLink, useLocation } from 'react-router-dom';
import { analyticsIcon, arrowLeftUp, dashAffilate, dashIcon, journalIcon, projectionIcon, settingsIcon, trackerSvg } from '../../assets/svg';
import { useGeneralContext } from '../../context';
import SkeletonLoaderItem from '../../Components/skeletonLoader';
import { useEffect } from 'react';
import useMediaQuery from '../../hooks/useMediaquery';
import { useAuthContext } from '../../context/auth-context/AuthProvider';

const DashboardSidebar = ({ skLoading }) => {
	const {
		state: { sidebarOpen },
		setSidebarOpen,
		setPaymentModalVisible,
	} = useGeneralContext();

	const { user } = useAuthContext();

	const navList = [
		{
			title: 'Overview',
			path: '/app/overview',
			icon: dashIcon,
		},
		{
			title: 'Journal',
			path: '/app/journals',
			icon: journalIcon,
		},
		{
			title: 'Tracker',
			path: '/app/tracker',
			icon: trackerSvg,
		},
		{
			title: 'Analytics',
			path: '/app/analytics',
			icon: analyticsIcon,
		},
		{
			title: 'Projection',
			path: '/app/projection',
			icon: projectionIcon,
		},
		{
			title: 'Setting',
			path: '/app/settings',
			icon: settingsIcon,
		},
		(user?.type === 'affiliate' || user?.type === 'admin') && {
			title: 'Affiliate overview',
			path: '/app/affiliate',
			icon: dashAffilate,
		},
		user?.type === 'admin' && {
			title: 'Admin overview',
			path: '/app/admin',
			icon: dashIcon,
		},
	];

	const location = useLocation();
	const isMobile = useMediaQuery('(max-width: 768px)');

	useEffect(() => {
		// execute on location change
		if (isMobile) {
			setSidebarOpen(false);
		}
		// eslint-disable-next-line
	}, [location]);

	return (
		<div className={`dasboard_sidebar ${sidebarOpen ? 'open' : ''}`}>
			<div className="nav_list">
				{navList.map(({ path, title, icon }) => {
					if (!path) return null;
					if (!skLoading) {
						return (
							<NavLink
								key={path}
								to={path}
								className={({ isActive }) => {
									return isActive ? 'active' : '';
								}}
							>
								{icon}
								<span>{title}</span>
							</NavLink>
						);
					}
					return (
						<div className="sk_loading" key={path}>
							<SkeletonLoaderItem className="sk_icon" />
							<SkeletonLoaderItem className="sk_nav" />
						</div>
					);
				})}
			</div>

			{!skLoading && !user?.paid && (
				<>
					{sidebarOpen ? (
						<div className="go_pro">
							<span>Get full access</span>
							<div className="pro_btn" onClick={() => setPaymentModalVisible(true)}>
								<span>Get Pro</span>
								{arrowLeftUp}
							</div>
						</div>
					) : (
						<div className="mar-t-32 pro_btn mar-x-4" onClick={() => setPaymentModalVisible(true)}>
							<span>Get Pro</span>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default DashboardSidebar;
