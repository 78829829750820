import axios from './config';

export default class Services {
  /**
   * @description
   */
  static async subscribePayment (data) {
    return axios({
      url: 'paystack/subscription',
      method: 'post',
      data,
    });
  }
  static async verifyPayment (ref) {
    return axios({
      url: `paystack/verify/${ref}`,
      method: 'get',
    });
  }
  static async getAffiliateComission (userId) {
    return axios({
      url: `commission/list/${userId}`,
      method: 'get',
    });
  }
}