import { DatePicker, Select, Table } from 'antd';
import InfoRateCard from '../../../Components/infoRateCard';
import { addIcon, rightAcoordion, sortIcon } from '../../../assets/svg';
import { useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../../../context/auth-context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { getDocuments, PAYOUTREQUEST, updateDocument, USERS } from '../../../firebase/firebaseFunctions';
import { useGeneralContext } from '../../../context';
import ButtonComponent from '../../../Components/Button/button';
import AppModal from '../../../Components/appModal';
import CustomInput from '../../../Components/forms/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { useNotificationContext } from '../../../context/notification-context/NotificationProvider';
import { adminUserTableColumn } from '../../../utils/data';
import PayoutDrawer from '../../../Components/PayoutDrawer';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AdminDashboard = () => {
	const { user, loading: userDataLoading } = useAuthContext();
	const {
		toggleAppLoading,
		// state: { appLoading },
	} = useGeneralContext();
	const [addAffiliateModal, setAddAffiliateModal] = useState(false);
	const [payoutDrawerVisible, setPayoutDrawerVisible] = useState(false);
	const [addUserLoading, setAddUserLoading] = useState(false);
	const [payouts, setPayouts] = useState([]);
	const [payoutLoading, setPayoutLoading] = useState(false);
	const { showErrorMessage, showSuccessMessage } = useNotificationContext();
	const [appUsers, setAppUsers] = useState([]);
	const [adminDataFilterObject, setAdminDataFilterObject] = useState({
		createdAt: null,
		paid: null,
		type: null,
	});

	const navigate = useNavigate();

	const onSubmit = async ({ email }) => {
		setAddUserLoading(true);
		try {
			const res = await getDocuments(USERS, { email });
			if (!res[0]?.id) throw new Error('User not found.');
			await updateDocument(USERS, res[0]?.id, { type: 'affiliate' });
			showSuccessMessage('User added as an affiliate.');
			setAddAffiliateModal(false);
		} catch (error) {
			showErrorMessage(error?.message || 'An error occured. Try again.');
		} finally {
			setAddUserLoading(false);
		}
	};

	const getUsers = async () => {
		toggleAppLoading(true);
		const users = await getDocuments(USERS, adminDataFilterObject);
		setAppUsers(users);
		toggleAppLoading(false);
	};

	useEffect(() => {
		if (!userDataLoading && user?.type !== 'admin') {
			navigate('/app/overview');
		}
	}, [user]);

	const { handleChange, handleBlur, handleSubmit, values, errors, touched } = useFormik({
		initialValues: {
			email: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			email: Yup.string().required().email().label('Email'),
		}),
		onSubmit,
	});

	const getPayouts = async () => {
		setPayoutLoading(true);
		try {
			const payouts = await getDocuments(PAYOUTREQUEST);
			setPayouts(payouts);
		} catch (error) {
		} finally {
			setPayoutLoading(false);
		}
	};

	const cardData = useMemo(() => {
		let userCount = 0,
			activeSub = 0;
		appUsers.forEach((v) => {
			// if (v?.createdAt) {
			//   const isCreatedThisMonth = dayjs().isSame(dayjs(v?.createdAt.toDate()), 'month');
			//   if (isCreatedThisMonth) {
			//     currentMonthRefCount += 1
			//   }
			// }
			if (v?.type !== 'admin') {
				userCount++;
			}
			if (v?.paid) {
				activeSub++;
			}
		});
		return {
			userCount,
			activeSub,
		};
	}, [appUsers]);

	const filteredPayout = useMemo(() => {
		return {
			requests: [...payouts].filter((v) => !v.paidOut),
			proccessed: [...payouts].filter((v) => v.paidOut),
		};
	}, [payouts]);

	useEffect(() => {
		getPayouts();
	}, []);

	useEffect(() => {
		getUsers();
	}, [adminDataFilterObject]);

	if (user?.type !== 'admin') {
		return <div className="admin_page"></div>;
	}

	return (
		<>
			<div className="admin_page">
				<div className="top">
					<span className="title">Admin</span>
					<div className="btns">
						<button className="button secondary" onClick={() => setPayoutDrawerVisible(true)}>
							<span>Payout Request</span>
							{rightAcoordion}
						</button>
						<button className="button primary" onClick={() => setAddAffiliateModal(true)}>
							{addIcon}
							<span>Add an Affiliate</span>
						</button>
					</div>
				</div>

				<div className="cards mar-b-0">
					<InfoRateCard title="Total users" value={cardData.userCount || '0'} />
					<InfoRateCard title="Active Subscription" value={cardData.activeSub || '0'} />
					<InfoRateCard title="Revenue" value={'0'} />
					<InfoRateCard title="This month revenue" value={'0'} />
					<InfoRateCard title="this month subscription" value={'0'} />
				</div>

				<div className="journal_table_wrapper">
					<div className="filters">
						<div className="filter">
							{sortIcon}
							<span>Filter by</span>
						</div>

						<RangePicker
							onChange={(value) => {
								setAdminDataFilterObject((prev) => {
									return { ...prev, createdAt: value };
								});
							}}
						/>

						<Select
							value={adminDataFilterObject?.paid}
							className="w-100"
							onChange={(value) => {
								setAdminDataFilterObject((prev) => {
									return { ...prev, paid: value };
								});
							}}
						>
							<Option value={null}>All</Option>
							<Option value={false}>Free User</Option>
							<Option value={true}>Pro User</Option>
						</Select>
						<Select
							value={adminDataFilterObject?.type}
							className="w-100"
							onChange={(value) => {
								setAdminDataFilterObject((prev) => {
									return { ...prev, type: value };
								});
							}}
						>
							<Option value={null}>All</Option>
							<Option value="user">Users</Option>
							<Option value="affiliate">Affiliates</Option>
							<Option value="admin">Admin</Option>
						</Select>
					</div>
					<Table scroll={{ x: 700 }} className="journal_table" dataSource={appUsers} columns={adminUserTableColumn} bordered={false} pagination={false} rowKey="id" />
				</div>
			</div>
			<AppModal width="489px" footer={false} closable={false} title="Add an affiliate" open={addAffiliateModal} onCancel={() => setAddAffiliateModal(false)}>
				<CustomInput
					label="Email"
					placeholder="Enter user email"
					name="email"
					type="email"
					onChange={handleChange('email')}
					errorVisible={touched['email']}
					errorMessage={errors['email']}
					onBlur={handleBlur('email')}
					value={values.email}
				/>
				<ButtonComponent loading={addUserLoading} buttonText="Save" className="button secondary mar-t-28" buttonWidth="100%" onClick={() => handleSubmit()} />
			</AppModal>
			<PayoutDrawer payoutReq={filteredPayout.requests} processedTrx={filteredPayout.proccessed} onClose={() => setPayoutDrawerVisible(false)} visible={payoutDrawerVisible} />
		</>
	);
};

export default AdminDashboard;
