import React, { useEffect, useState } from 'react';
import CustomInput from '../../../Components/forms/Input';
import CustomSelect from '../../../Components/forms/select';
import ShareModal from '../../../Components/shareModal';
import { closeCircle, roundImage } from '../../../assets/svg';
import { Select, Checkbox } from 'antd';
import './styles.scss';
import TextArea from '../../../Components/forms/TextArea';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getDownloadURL, ref as storageRef, uploadBytes, uploadString } from 'firebase/storage';
import ButtonComponent from '../../../Components/Button/button';
import { useAuthContext } from '../../../context/auth-context/AuthProvider';
import { generateRandomId } from '../../../utils/helpers';
import { useNotificationContext } from '../../../context/notification-context/NotificationProvider';
import { storage } from '../../../firebase/config';
import { createJournal, getActiveTracker, getJournals, getSingleJournal, JOURNALS, updateDocument } from '../../../firebase/firebaseFunctions';
import { useGeneralContext } from '../../../context';
import { getConfluences } from '../../../firebase/firebaseFunctions';
import ConfluenceModal from '../settings/modals/confluenceModal';
import { createConfluence } from '../../../firebase/firebaseFunctions';
import { captureScreen } from '../../../utils/captureScreenScript';
import InstrumentWidget from '../../../Components/instrument-widget/InstrumentWidget';
import dayjs from 'dayjs';

const { Option } = Select;
const { BASE64TYPE, FILETYPE } = {
	BASE64TYPE: 'base64',
	FILETYPE: 'filetype',
};
const { MOBILE, COMPUTER } = {
	MOBILE: 'mobile',
	COMPUTER: 'computer',
};
const AddJournal = () => {
	const navigate = useNavigate();
	const { journalId } = useParams();
	const { toggleAppLoading, setPaymentModalVisible } = useGeneralContext();
	const { user } = useAuthContext();
	const { showErrorMessage, showSuccessMessage, showInfoMessage } = useNotificationContext();

	const [isSubmitting, setSubmitting] = useState(false);
	const [confluencesData, setConfluencesData] = useState([]);
	const [activeTracker, setActiveTracker] = useState(null);
	const [imageType, setImageType] = useState('');
	// const [checkedConfluence, setCheckedConfluence] = useState([]);
	const [openAddConfluenceModal, setOpenAddConfluenceModal] = useState(false);
	const [device, setDevice] = useState('');
	const [showShareModal, setShowShareModal] = useState(false);
	// const handleCheckedConfluenceChange = (value, checked) => {
	// 	if (checked) {
	// 		// If checkbox is checked, add value to the array
	// 		setCheckedConfluence((prevValues) => [...prevValues, value]);
	// 	} else {
	// 		// If checkbox is unchecked, remove value from the array
	// 		setCheckedConfluence((prevValues) => prevValues.filter((item) => item !== value));
	// 	}
	// };

	//   useEffect(() => {
	// 	async() => {
	// 		const mobileResult =  isMobile()
	// 		// console.log(mobileResult)
	// 	}
	//   }, [third])

	//   const isMobile = () => {
	// 	const userAgent = navigator.userAgent;
	// 	return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg

	const onSubmit = async (values, actions) => {
		if (isSubmitting) return;
		setSubmitting(true);
		try {
			if (!journalId && !user?.paid && !user?.userOnFreeMode) {
				const data = await getJournals(user?.uid, { timeStamp: [dayjs(), dayjs()] });
				if (data.length >= 2) {
					showInfoMessage('You are currently on a free plan and only allowed to two (2) jornal per day');
					setPaymentModalVisible(true);
					return;
				}
			}
			if (typeof values?.imageSrc === 'object') {
				values.imageSrc = await uploadImage(values.imageSrc);
			}
			if (journalId) {
				await updateJournalFunc(values);
			} else {
				await addJournal(values);
				setShowShareModal(true);
			}
		} catch (error) {
			showErrorMessage(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	const { handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue, resetForm } = useFormik({
		initialValues: {
			imageSrc: '',
			currencyPair: undefined,
			orderType: undefined,
			entryPrice: '',
			lotSize: '',
			stopLoss: '',
			takeProfit: '',
			status: undefined,
			tradeOutcome: '',
			reason: [],
			generalComment: '',
			trackerId: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			currencyPair: Yup.string().required().label('Currency pair'),
			orderType: Yup.string().required().label('Order type'),
			entryPrice: Yup.string().required().label('Entry price'),
			lotSize: Yup.string().required().label('Lot size'),
			// stopLoss: Yup.string().required().label('Stop Loss'),
			// takeProfit: Yup.string().required().label('Take profit'),
			tradeOutcome: Yup.string().when('status', (status, field) => (status.includes('Done') ? field.required().label('Outcome') : field)),
			status: Yup.string().required().label('Status'),
			reason: Yup.array().min(1, 'Select atleast 1 confluence').required().label('Reason'),
		}),
		onSubmit,
	});

	const updateJournalFunc = async (values) => {
		const { image, ...rest } = values;
		await updateDocument(JOURNALS, journalId, { ...rest });
		resetForm();
		showSuccessMessage('Journal Updated successfully');
		navigate('/app/journals');
	};

	const addJournal = async (values) => {
		setSubmitting(true);
		const { image, ...rest } = values;
		await createJournal({ ...rest, userId: user.uid });
		resetForm();
		showSuccessMessage('Journal created successfully');
	};

	const uploadImage = async (imageFile) => {
		const refId = generateRandomId();
		try {
			const imageRef = storageRef(storage, `journalsImages/${user.uid}-${refId}`);
			if (imageType === BASE64TYPE) {
				const base64Snapshot = await uploadString(imageRef, imageFile, 'data_url');
				return await getDownloadURL(base64Snapshot.ref);
			}
			if (imageType === FILETYPE) {
				const snapshot = await uploadBytes(imageRef, imageFile);
				return await getDownloadURL(snapshot.ref);
			}
		} catch (error) {
			showErrorMessage('Error uploading this image. Please try adding image again');
		}
	};

	const getJournal = async () => {
		try {
			toggleAppLoading();
			const res = await getSingleJournal(journalId);
			if (!res) throw new Error('Journal not found');
			setFieldValue('currencyPair', res.currencyPair);
			setFieldValue('entryPrice', res.entryPrice);
			setFieldValue('generalComment', res.generalComment);
			setFieldValue('lotSize', res.lotSize);
			setFieldValue('orderType', res.orderType);
			setFieldValue('reason', res.reason);
			setFieldValue('status', res.status);
			setFieldValue('stopLoss', res.stopLoss);
			setFieldValue('takeProfit', res.takeProfit);
			setFieldValue('tradeOutcome', res.tradeOutcome);
			setFieldValue('imageSrc', res.imageSrc);
			setFieldValue('trackerId', res.trackerId);
			// setActiveTracker(res.trackerId);
		} catch (error) {
			// console.log(error);
			showErrorMessage(error?.message);
			navigate('/app/journals');
		} finally {
			toggleAppLoading(false);
		}
	};

	const getActiveTrackerFunc = async () => {
		try {
			const res = await getActiveTracker(user.uid);
			setActiveTracker(res);
			setFieldValue('trackerId', res.id);
		} catch (error) {}
	};

	useEffect(() => {
		if (journalId) {
			getJournal();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const getConfluencesFunc = async () => {
			try {
				const data = await getConfluences(user?.uid);
				const confluenceArray = data.map((obj) => obj['confluence']);
				setConfluencesData(confluenceArray);
			} catch (err) {
				showErrorMessage(err?.message);
			}
		};
		getConfluencesFunc();
	}, [openAddConfluenceModal, showErrorMessage, user]);

	useEffect(() => {
		if (!journalId) {
			getActiveTrackerFunc();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const userAgent = navigator.userAgent.toLowerCase();
		if (/mobile|android|iphone|ipad|phone/i.test(userAgent)) {
			setDevice(MOBILE);
		} else {
			setDevice(COMPUTER);
		}
	}, []);

	return (
		<>
			<div className="add_journal_page">
				<div className="top">
					<h5>{journalId ? 'Update Journal' : 'Add New Journal'}</h5>
					<span className="cursor-pointer" onClick={() => navigate('/app/journals')}>
						{closeCircle}
					</span>
				</div>
				<div className="image_upload_wrapper">
					<span>Add Image (Optional)</span>
					<div className="image_upload">
						{values.imageSrc ? (
							<div className="journal_image">
								<img src={typeof values.imageSrc === 'object' ? URL.createObjectURL(values.imageSrc) : values.imageSrc} alt="journal visual" />
								<div className="delete cursor-pointer" onClick={() => setFieldValue('imageSrc', null)}>
									{closeCircle}
								</div>
							</div>
						) : (
							<>
								{roundImage}
								<label htmlFor="journal_image" className="cursor-pointer">
									Upload from device
								</label>
								<input
									id="journal_image"
									type="file"
									style={{ display: 'none' }}
									accept="image/*"
									onChange={(e) => {
										if (!e.target.files) return;
										if (e.target.files[0].type.includes('image')) {
											setImageType(FILETYPE);
											setFieldValue('imageSrc', e.target.files[0]);
										}
									}}
								/>
								{device === COMPUTER && (
									<label
										className="chart-screenshot_label cursor-pointer"
										onClick={() => {
											setImageType(BASE64TYPE);
											captureScreen(values.imageSrc, setFieldValue);
										}}
									>
										Take Chart Screenshot
									</label>
								)}
							</>
						)}
					</div>
				</div>

				<div className="forms_input">
					<InstrumentWidget
						title="Instrument"
						// onChange={(value) => handleCurrencyPairChange(value)}
						onChange={(value) => {
							setFieldValue('currencyPair', value.toLowerCase());
						}}
						value={values.currencyPair}
						className="form_item"
						errorVisible={touched['currencyPair']}
						errorMessage={errors['currencyPair']}
						onBlur={handleBlur('currencyPair')}
					/>
					<CustomSelect
						label="Order Type"
						className="form_item"
						placeholder="Select order type"
						onChange={handleChange('orderType')}
						errorVisible={touched['orderType']}
						errorMessage={errors['orderType']}
						onBlur={handleBlur('orderType')}
						value={values.orderType}
					>
						<Option value="SELL">Sell</Option>
						<Option value="BUY">Buy</Option>
					</CustomSelect>

					<CustomInput
						label="Entry Price"
						type="number"
						className="form_item"
						placeholder="Enter entry price"
						onChange={handleChange('entryPrice')}
						errorVisible={touched['entryPrice']}
						errorMessage={errors['entryPrice']}
						onBlur={handleBlur('entryPrice')}
						value={values.entryPrice}
					/>
					<CustomInput
						label="Lot Size"
						type="number"
						className="form_item"
						placeholder="Enter lot size"
						onChange={handleChange('lotSize')}
						errorVisible={touched['lotSize']}
						errorMessage={errors['lotSize']}
						onBlur={handleBlur('lotSize')}
						value={values.lotSize}
					/>
					<CustomInput
						label="Stop Loss (in pips)"
						type="number"
						className="form_item"
						placeholder="Enter SL"
						onChange={handleChange('stopLoss')}
						errorVisible={touched['stopLoss']}
						errorMessage={errors['stopLoss']}
						onBlur={handleBlur('stopLoss')}
						value={values.stopLoss}
					/>
					<CustomInput
						label="Take Profit (in pips)"
						type="number"
						className="form_item"
						placeholder="Enter TP"
						onChange={handleChange('takeProfit')}
						errorVisible={touched['takeProfit']}
						errorMessage={errors['takeProfit']}
						onBlur={handleBlur('takeProfit')}
						value={values.takeProfit}
					/>

					{/* <div className="check">
					<Checkbox>This trade is currently closed</Checkbox>
				</div> */}
					<CustomSelect
						label="Status"
						className="trade_outcome"
						placeholder="Select status"
						onChange={handleChange('status')}
						errorVisible={touched['status']}
						errorMessage={errors['status']}
						onBlur={handleBlur('status')}
						value={values.status}
					>
						<Option value="Pending">Pending</Option>
						<Option value="In Progrss">In progress</Option>
						<Option value="Done">Done</Option>
					</CustomSelect>
					{values.status === 'Done' && (
						<CustomSelect
							label="Trade Outcome"
							className="trade_outcome"
							placeholder="Trade outcome"
							onChange={handleChange('tradeOutcome')}
							errorVisible={touched['tradeOutcome']}
							errorMessage={errors['tradeOutcome']}
							onBlur={handleBlur('tradeOutcome')}
							value={values.tradeOutcome}
						>
							<Option value="Profit">Profit</Option>
							<Option value="Breakeven">Breakeven</Option>
							<Option value="Loss">Loss</Option>
						</CustomSelect>
					)}
				</div>
				{activeTracker && !journalId && (
					<>
						<br />
						<Checkbox
							onChange={(e) => {
								if (e.target.checked) {
									setFieldValue('trackerId', activeTracker?.id);
								} else {
									setFieldValue('trackerId', '');
								}
							}}
							checked={values?.trackerId === activeTracker?.id}
						>
							<span>Add to current tracker</span>
						</Checkbox>
					</>
				)}
				<br />
				<div className="confluences-container">
					<p className="heading">Trade Confluence</p>
					<p className="description">
						You can find all your confluence and add more in Settings -- Trade settings.{' '}
						<span className="add-confluence-text" onClick={() => setOpenAddConfluenceModal(true)}>
							Click here to add new{' '}
						</span>
					</p>
					<div className="confluence-inner_container">
						{confluencesData?.map((confluence) => (
							<div className="confluence">
								<Checkbox
									onChange={(e) => {
										if (e.target.checked) {
											let newconfluence = [...values.reason, confluence];
											setFieldValue('reason', newconfluence);
										} else {
											let newVlaue = values.reason.filter((val) => val !== confluence);
											setFieldValue('reason', newVlaue);
										}
									}}
									checked={values?.reason?.includes(confluence)}
								>
									{confluence}
								</Checkbox>
							</div>
						))}
					</div>
					{touched['reason'] && errors['reason'] && <small style={{ color: '#d50000' }}>{errors['reason']}</small>}
				</div>
				<ConfluenceModal
					state={openAddConfluenceModal}
					setState={setOpenAddConfluenceModal}
					user={user}
					createConfluence={createConfluence}
					showErrorMessage={showErrorMessage}
					showSuccessMessage={showSuccessMessage}
					// setDataLoading={setDataLoading}
				/>
				<TextArea
					label="General Comment"
					className="w-100"
					onChange={handleChange('generalComment')}
					errorVisible={touched['generalComment']}
					errorMessage={errors['generalComment']}
					onBlur={handleBlur('generalComment')}
					value={values.generalComment}
				/>
				<ButtonComponent buttonText={journalId ? 'Update' : 'Add Journal'} className="button secondary" buttonWidth="100%" loading={isSubmitting} onClick={() => handleSubmit()} />
			</div>
			<ShareModal
				title={'I just jornal a new trade. Click here to start your jornaling journey today.'}
				shareUrl="https://spillmetrics.com"
				visible={showShareModal}
				onClose={() => setShowShareModal(false)}
			/>
		</>
	);
};

export default AddJournal;
