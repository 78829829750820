import { Dropdown } from 'antd';
import './styles.scss';
const AppDropdown = ({ dropdownItems = [], trigger = ['click'], label }) => {
	return (
		<Dropdown menu={{ items: dropdownItems }} trigger={trigger}>
			{label || (
				<div className="dots">
					<span></span>
					<span></span>
					<span></span>
				</div>
			)}
		</Dropdown>
	);
};

export default AppDropdown;
