import './styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { notificationVariant } from '../../utils/helpers';
import { Select } from 'antd';

const CustomSelect = ({
	children,
	errorMessage,
	errorVisible,
	onBlur,
	onChange,
	placeholder,
	disabled,
	label,
	className,
	value,
	defaultValue,
	loading,
	notFoundContent,
	onSearch,
	onSelect,
	showSearch,
}) => {
	return (
		<div className={`custom_input ${className || ''}`}>
			<label>{label}</label>

			<Select
				loading={loading}
				showSearch={showSearch}
				onSelect={(val) => onSelect && onSelect(val)}
				className={`custom_select  ${errorVisible && errorMessage ? 'error' : ''}`}
				placeholder={placeholder}
				optionFilterProp="children"
				onChange={onChange}
				defaultValue={defaultValue}
				disabled={disabled}
				onSearch={onSearch}
				value={value}
				onBlur={onBlur}
				notFoundContent={notFoundContent}
				filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
			>
				{children}
			</Select>

			<AnimatePresence>
				{errorVisible && errorMessage && (
					<motion.div variants={notificationVariant} initial="hidden" animate="visible" exit="exit" className="error_message">
						<small>{errorMessage}</small>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default CustomSelect;
