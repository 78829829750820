import { useState } from 'react';
import './styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { notificationVariant } from '../../utils/helpers';
import { eye, eye_slash } from '../../assets/svg';

const PasswordInput = ({ name, errorMessage, errorVisible, onBlur, onChange, placeholder, disabled, value, label, className }) => {
	const [type, setType] = useState('password');

	const toggleType = () => {
		setType((prevState) => {
			if (prevState === 'password') {
				return 'text';
			}
			return 'password';
		});
	};
	return (
		<div className={`custom_input ${className || ''}`}>
			{label && <label>{label}</label>}
			<div className="password_input">
				<input
					type={type}
					className={errorVisible && errorMessage ? 'error' : ''}
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					disabled={disabled}
					onBlur={onBlur}
				/>
				<span className="eye_icon" onClick={toggleType}>
					{type === 'password' ? eye_slash : eye}
				</span>
			</div>

			<AnimatePresence>
				{errorVisible && errorMessage && (
					<motion.div variants={notificationVariant} initial="hidden" animate="visible" exit="exit" className="error_message">
						<small>{errorMessage}</small>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default PasswordInput;
