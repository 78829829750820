import { copyIcon } from '../../assets/svg';
import AppModal from '../appModal';
import './styles.scss';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';
import ButtonComponent from '../Button/button';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import { useState } from 'react';
import CustomInput from '../forms/Input';
import { getDocuments, updateDocument, USERS } from '../../firebase/firebaseFunctions';
const AffiliateLinkModal = ({ visible, onClose }) => {
	const { showSuccessMessage, showErrorMessage } = useNotificationContext();
	const { user, setUser } = useAuthContext();
	const [formVisible, setFormVisible] = useState(false);
	const [refCodeChanging, setRefCodeChanging] = useState(false);
	const link = `http://spillmetrics/signup?ref=${user.refCode}`;
	const [refCodeValue, setRefCodeValue] = useState(user.refCode);
	const [refCodeError, setRefCodeError] = useState(null);

	const handleChangeRefCode = async () => {
		if (refCodeValue === user.refCode || refCodeValue === '') return;

		setRefCodeChanging(true);
		setRefCodeError(null);
		try {
			let usersWithCode = await getDocuments(USERS, { refCode: refCodeValue });

			if (usersWithCode.length >= 1) {
				setRefCodeError('Referral code already in use.');
				return;
			}
			await updateDocument(USERS, user.id, { refCode: refCodeValue });
			setUser({ ...user, refCode: refCodeValue });
			showSuccessMessage('Success');
			setFormVisible(false);
		} catch (error) {
			showErrorMessage(error?.message || 'An error occoured');
		} finally {
			setRefCodeChanging(false);
		}
	};

	return (
		<AppModal
			width="689px"
			footer={false}
			closable={false}
			open={visible}
			title={formVisible ? 'Change referral code' : 'Share with your friends'}
			onCancel={() => {
				setRefCodeError(null);
				setFormVisible(false);
				onClose();
			}}
		>
			{!formVisible ? (
				<div className="affiliate_link_modal">
					<span>Send this link to people to begin earning money</span>
					<div className="link_wrapper">
						<span>{link}</span>
						<div
							className="copy_btn"
							onClick={() => {
								navigator.clipboard.writeText(link);
								showSuccessMessage('Link copied');
							}}
						>
							{copyIcon}
							<span>Copy link</span>
						</div>
					</div>
					<ButtonComponent buttonText="Change referral code" className="button secondary mar-t-28" buttonWidth="100%" onClick={() => setFormVisible(true)} />
				</div>
			) : (
				<div>
					<CustomInput
						label="Refferal code"
						placeholder="Enter new referral code"
						name="refCode"
						type="text"
						onChange={(e) => setRefCodeValue(e.target.value)}
						value={refCodeValue}
						errorVisible={refCodeError}
						errorMessage={refCodeError}
					/>
					<ButtonComponent loading={refCodeChanging} buttonText="Save" className="button secondary mar-t-28" buttonWidth="100%" onClick={() => handleChangeRefCode()} />
				</div>
			)}
		</AppModal>
	);
};

export default AffiliateLinkModal;
