import { addIcon, closeCircle, exportIcon, sortIcon } from '../../../assets/svg';
import './styles.scss';
import { Select, Modal, Checkbox, DatePicker, Dropdown } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getJournals } from '../../../firebase/firebaseFunctions';
import { useNotificationContext } from '../../../context/notification-context/NotificationProvider';
import { useAuthContext } from '../../../context/auth-context/AuthProvider';
import { Table } from 'antd';
import { journalTableColum } from '../../../utils/data';
import JournaDetailsDrawer from '../../../Components/journalDrawer';
import AppDropdown from '../../../Components/Dropdown';
const { Option } = Select;
const { RangePicker } = DatePicker;

const JournalPage = () => {
	const navigate = useNavigate();
	const [openExportModal, setOpenExportModal] = useState(false);
	const { showErrorMessage } = useNotificationContext();
	const [dataLoading, setDataLoading] = useState(false);
	const [journalData, setJournalData] = useState([]);
	const [openTradeDetailsDrawer, setOpenTradeDetailsDrawer] = useState(false);
	const [selectedJournal, setSelectedJournal] = useState({});
	const [journalDataFilterObject, setJournalDataFilterObject] = useState({
		currencyPair: null,
		orderType: null,
		tradeOutcome: null,
		timeStamp: null,
	});
	const { user } = useAuthContext();

	const getJournalsFunc = async () => {
		setDataLoading(true);
		try {
			const data = await getJournals(user?.uid, journalDataFilterObject);
			setJournalData(data);
		} catch (error) {
			// console.log(error);
			showErrorMessage(error?.message);
		} finally {
			setDataLoading(false);
		}
	};

	const dropdownItems = [
		{
			label: <span onClick={() => navigate('/app/journals/add')}>Add</span>,
			key: '0',
		},
		{
			label: <span onClick={() => setOpenExportModal(true)}>Export</span>,
			key: '1',
		},
	];

	useEffect(() => {
		getJournalsFunc();
	}, [journalDataFilterObject]);
	return (
		<>
			<div className="journal_page">
				<div className="top">
					<span className="page_heading">Trading Journal</span>
					<div className="buttons_wrapper">
						<button className="button primary" onClick={() => navigate('/app/journals/add')}>
							{addIcon}
							<span>Add new</span>
						</button>
						<button className="button secondary" onClick={() => setOpenExportModal(true)}>
							{exportIcon}
							Export
						</button>
					</div>
					<div className="dropdown_wrapper">
						<AppDropdown dropdownItems={dropdownItems} />
					</div>
				</div>
				<div className="journal_table_wrapper">
					<div className="filters">
						<div className="filter">
							{sortIcon}
							<span>Filter by</span>
						</div>

						<RangePicker
							onChange={(value) => {
								setJournalDataFilterObject((prev) => {
									return { ...prev, timeStamp: value };
								});
							}}
						/>

						<Select
							value={journalDataFilterObject?.currencyPair}
							className="w-100"
							onChange={(value) => {
								setJournalDataFilterObject((prev) => {
									return { ...prev, currencyPair: value };
								});
							}}
						>
							<Option value={null}>All</Option>
							<Option value="GBP/USD">GBP/USD</Option>
							<Option value="XAU/USD">XAU/USD</Option>
							<Option value="US30">US30</Option>
							<Option value="NAS100">NAS100</Option>
						</Select>

						<Select
							value={journalDataFilterObject?.orderType}
							className="w-100"
							onChange={(value) => {
								setJournalDataFilterObject((prev) => {
									return { ...prev, orderType: value };
								});
							}}
						>
							<Option value={null}>All</Option>
							<Option value="SELL">Sell</Option>
							<Option value="BUY">Buy</Option>
						</Select>

						<Select
							value={journalDataFilterObject?.tradeOutcome}
							className="w-100"
							onChange={(value) => {
								setJournalDataFilterObject((prev) => {
									return { ...prev, tradeOutcome: value };
								});
							}}
						>
							<Option value={null}>All</Option>
							<Option value="Profit">Profit</Option>
							<Option value="Breakeven">Breakeven</Option>
							<Option value="Loss">Loss</Option>
						</Select>
					</div>
					<Table
						scroll={{ x: 700 }}
						className="journal_table"
						loading={dataLoading}
						dataSource={Array.isArray(journalData) ? journalData : []}
						columns={journalTableColum(navigate)}
						bordered={false}
						pagination={false}
						rowKey="id"
						onRow={(record, rowIndex) => {
							return {
								onClick: (event) => {
									setOpenTradeDetailsDrawer(true);
									setSelectedJournal(record);
								},
							};
						}}
						rowClassName="cursor-pointer"
					/>
				</div>
			</div>

			<Modal footer={false} closable={false} className="custom_modal" width="414px" open={openExportModal} onCancel={() => setOpenExportModal(false)}>
				<div className="export_modal">
					<div className="top">
						<span>Export Trading Journal</span>
						<span className="cursor-pointer" onClick={() => setOpenExportModal(false)}>
							{closeCircle}
						</span>
					</div>
					<div className="format">
						<span>Export Format</span>
						<Checkbox>PDF</Checkbox>
						<Checkbox>Excel</Checkbox>
					</div>
					<div className="export_range">
						<span>Export Range</span>

						<div className="date_select">
							<RangePicker />
						</div>
					</div>
					<button className="button secondary">Export PDF</button>
				</div>
			</Modal>
			<JournaDetailsDrawer visible={openTradeDetailsDrawer} onClose={() => setOpenTradeDetailsDrawer(false)} journalObject={selectedJournal} />
		</>
	);
};

export default JournalPage;
