import { DatePicker, Select, Table } from 'antd';
import InfoRateCard from '../../../Components/infoRateCard';
import { affiliateTableColumn } from '../../../utils/data';
import './styles.scss';
import { sortIcon } from '../../../assets/svg';
import AffiliateLinkModal from '../../../Components/affiliateLinkModal';
import { useEffect, useMemo, useState } from 'react';
import AddAccountModal from '../../../Components/addAccountModal';
import RequestPayoutModal from '../../../Components/requestPayoutModal';
import { useAuthContext } from '../../../context/auth-context/AuthProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAffiliateData, getDocuments } from '../../../firebase/firebaseFunctions';
import { useGeneralContext } from '../../../context';
import Services from '../../../api/services';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AffiliateDashboard = () => {
	const { user, loading: userDataLoading } = useAuthContext();
	const {
		toggleAppLoading,
		// state: { appLoading },
	} = useGeneralContext();
	const [searchParams] = useSearchParams();
	const refUserId = searchParams.get('u');

	const navigate = useNavigate();

	const [showAffiliateLinkModal, setShowAffiliateLinkModal] = useState(false);
	const [showAccountModal, setShowAccountModal] = useState(false);
	const [showRequestPayoutModal, setShowRequestPayoutModal] = useState(false);
	const [data, setData] = useState({});
	const [payoutAccount, setPayoutAccount] = useState({});
	const [affiliateDataFilterObject, setAffiliateDataFilterObject] = useState({
		createdAt: null,
		paid: null,
	});

	const getAffiliateDataFunc = async () => {
		const userID = refUserId || user?.uid;
		toggleAppLoading(true);
		try {
			const res = await getAffiliateData(userID, affiliateDataFilterObject);
			const accountDetails = await getDocuments('payoutAccounts', { userId: userID });
			setPayoutAccount(accountDetails[0]);
			setData(res);
		} catch (error) {
			// // console.log('====================================');
			// // console.log(error);
			// // console.log('====================================');
		} finally {
			toggleAppLoading(false);
		}
	};

	const getAffilaiteComission = async () => {
		const userID = refUserId || user?.uid;
		try {
			const res = await Services.getAffiliateComission(userID);
			// console.log(res);
		} catch (error) {
			// console.log(error);
		}
	};

	const isCurrentUser = useMemo(() => {
		if (!refUserId) return true;
		return user.uid === refUserId;
	}, [user, refUserId]);

	useEffect(() => {
		getAffiliateDataFunc();
		getAffilaiteComission();
	}, [affiliateDataFilterObject]);

	useEffect(() => {
		if (!userDataLoading && user.type !== 'affiliate' && user.type !== 'admin') {
			navigate('/app/overview');
		}
	}, [user]);

	if (user.type !== 'affiliate' && user.type !== 'admin') {
		return <div className="affiliate_page"></div>;
	}
	return (
		<>
			<div className="affiliate_page">
				<div className="top">
					<span className="title">Affiliate Dashboard</span>
					<div className="btns">
						{isCurrentUser && (
							<button
								className="button primary"
								onClick={() => {
									if (payoutAccount?.id) {
										setShowRequestPayoutModal(true);
									} else {
										setShowAccountModal(true);
									}
								}}
							>
								Request Payout
							</button>
						)}
						<button className="button secondary" onClick={() => setShowAffiliateLinkModal(true)}>
							Copy affiliate link
						</button>
					</div>
				</div>

				<div className="aff_card_wrapper">
					<div className="cards_wrapper">
						<span>Earnings</span>
						<div className="cards">
							<InfoRateCard title="This month Earnings" value={data?.currentMonthEarnings || '0'} />
							<InfoRateCard title="Overall Earnings" value={data?.overallEarnings || '0'} />
							<InfoRateCard title="Unpaid Earnings" value={data?.unpaidEarnings || '0'} />
						</div>
						<span>Referrals</span>
						<div className="cards mar-b-0">
							<InfoRateCard title="This month Referrals" value={data?.currentMonthRefCount || '0'} />
							<InfoRateCard title="Overall Referrals" value={data?.totalRefCount || '0'} />
							<InfoRateCard title="Pro Referrals" value={data?.proRefCount || '0'} />
						</div>
					</div>
					<div className="withdrawal_details_wrapper">
						{payoutAccount?.id ? (
							<div className="payout_account_details">
								<div className="heading">
									<h4>{isCurrentUser && 'Your'} Payout account</h4>
									{isCurrentUser && (
										<span className="cursor-pointer" onClick={() => setShowAccountModal(true)}>
											Change account
										</span>
									)}
								</div>
								<table width="100%">
									<tr>
										<td>Account Holder:</td>
										<td>{payoutAccount?.accountName}</td>
									</tr>
									<tr>
										<td>Account number:</td>
										<td>{payoutAccount?.accountNumber}</td>
									</tr>
									<tr>
										<td>Bank name:</td>
										<td>{payoutAccount?.bankName}</td>
									</tr>
								</table>
							</div>
						) : (
							<>
								{isCurrentUser ? (
									<div className="no_account">
										<span>Add an account number for Payouts</span>
										<button className="button secondary" onClick={() => setShowAccountModal(true)}>
											Add
										</button>
									</div>
								) : (
									<div className="no_account">
										<span>No Payout Account Added.</span>
									</div>
								)}
							</>
						)}
					</div>
				</div>

				<div className="journal_table_wrapper">
					<div className="filters">
						<div className="filter">
							{sortIcon}
							<span>Filter by</span>
						</div>

						<RangePicker
							onChange={(value) => {
								setAffiliateDataFilterObject((prev) => {
									return { ...prev, createdAt: value };
								});
							}}
						/>

						<Select
							value={affiliateDataFilterObject?.paid}
							className="w-100"
							onChange={(value) => {
								setAffiliateDataFilterObject((prev) => {
									return { ...prev, paid: value };
								});
							}}
						>
							<Option value={null}>All</Option>
							<Option value={false}>Free User</Option>
							<Option value={true}>Pro User</Option>
						</Select>
					</div>
					<Table scroll={{ x: 700 }} className="journal_table" dataSource={data?.referrals || []} columns={affiliateTableColumn} bordered={false} pagination={false} rowKey="id" />
				</div>
			</div>
			<AffiliateLinkModal visible={showAffiliateLinkModal} onClose={() => setShowAffiliateLinkModal(false)} />
			{isCurrentUser && (
				<AddAccountModal
					accountDetails={payoutAccount}
					visible={showAccountModal}
					onClose={(val) => {
						if (val) {
							setPayoutAccount((prev) => ({
								...prev,
								id: val?.id,
								accountName: val?.accountName,
								accountNumber: val?.accountNumber,
								bankName: val?.bankName,
							}));
						}
						setShowAccountModal(false);
					}}
				/>
			)}
			{isCurrentUser && (
				<RequestPayoutModal
					userDetails={{ ...payoutAccount, name: user?.displayName, email: user?.email, userId: user.uid }}
					visible={showRequestPayoutModal}
					onClose={() => setShowRequestPayoutModal(false)}
				/>
			)}
		</>
	);
};

export default AffiliateDashboard;
