import { TimePicker } from 'antd';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { notificationVariant } from '../../utils/helpers';
import './styles.scss';
import moment from 'moment';

const TimeInput = ({ className, disabled, onChange, placeholder, dateFormat, value, label, errorMessage, errorVisible, onBlur }) => {
	return (
		<div className={`custom_input ${className || ''}`}>
			{label && <label>{label}</label>}
			<TimePicker
				use12Hours={true}
				onBlur={onBlur}
				className={`custom_time_picker ${errorVisible && errorMessage ? 'error' : ''}`}
				format="h:mm a"
				// bordered={false}
				disabled={disabled}
				style={{ width: '100%', height: '100%' }}
				placeholder={placeholder}
				onChange={(time, timeString) => onChange(timeString)}
				value={value ? moment(value, dateFormat || 'h:mm a') : undefined}
			/>
			<AnimatePresence>
				{errorVisible && errorMessage && (
					<motion.div variants={notificationVariant} initial="hidden" animate="visible" exit="exit" className={'error_message'}>
						<small>{errorMessage}</small>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default TimeInput;
