import { addIcon } from "../../../assets/svg";
import { useNavigate } from "react-router-dom";
import CreateTrackerModal from "../../../Components/trackerCard/createTrackerModal";
import { useEffect, useState } from "react";
import {
  deleteTracker,
  getTrackerList,
} from "../../../firebase/firebaseFunctions";
import SkeletonLoaderItem from "../../../Components/skeletonLoader";
import { useAuthContext } from "../../../context/auth-context/AuthProvider";
import { getTrackerStatus } from "../../../utils/helpers";
import dayjs from "dayjs";
import { Popconfirm } from "antd";
import "./styles.scss";
import AppDropdown from "../../../Components/Dropdown";

const TrackersPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [showCreateTrackerModal, setShowCreateTrackerModal] = useState(false);
  const [gettingTrackerLoading, setGettingTrackerLoading] = useState(false);
  const [ongoingTrackers, setOngoingTrackers] = useState([]);
  const [upcomingTrackers, setUpcomingTrackers] = useState([]);
  const [closedTrackers, setClosedTrackers] = useState([]);

  const getTrackerListFunc = async () => {
    setGettingTrackerLoading(true);
    try {
      const res = await getTrackerList(user.uid);
      let ongoing = [];
      let closed = [];
      let upcoming = [];
      res.forEach((t) => {
        let status = getTrackerStatus(t.startDate, t.endDate);
        if (status === "ongoing") {
          ongoing.push(t);
        }
        if (status === "closed") {
          closed.push(t);
        }
        if (status === "upcoming") {
          upcoming.push(t);
        }
      });
      setOngoingTrackers(ongoing);
      setClosedTrackers(closed);
      setUpcomingTrackers(upcoming);
    } catch (error) {
    } finally {
      setGettingTrackerLoading(false);
    }
  };
  useEffect(() => {
    getTrackerListFunc();
  }, []);
  return (
    <>
      <div className="trackers_wrapper">
        <div className="top">
          <span>Trade Tracker</span>
          <button
            className="button primary"
            onClick={() => setShowCreateTrackerModal(true)}
          >
            {addIcon}
            <span>Add new</span>
          </button>
        </div>
        <div className="tracker_list_wrapper">
          <div className="tracker_list">
            {gettingTrackerLoading ? (
              <>
                <TrackerCard skLoading={true} />
                <TrackerCard skLoading={true} />
                <TrackerCard skLoading={true} />
                <TrackerCard skLoading={true} />
                <TrackerCard skLoading={true} />
              </>
            ) : (
              <>
                {ongoingTrackers.length >= 1 && (
                  <span className="list_title">Ongoing Tracker</span>
                )}
                {ongoingTrackers?.map((t) => (
                  <TrackerCard
                    key={t?.id}
                    name={t?.trackerName}
                    date={`${dayjs(t?.startDate).format("DD MMM")} - ${dayjs(
                      t?.endDate
                    ).format("DD MMM")}`}
                    days={`${t?.dateRange?.length} days`}
                    status="ongoing"
                    onView={() => navigate(`/app/tracker/${t.id}`)}
                    onDelete={async () => {
                      await deleteTracker(t?.id);
                      setOngoingTrackers((prev) =>
                        prev.filter((trcaker) => trcaker?.id !== t?.id)
                      );
                    }}
                  />
                ))}
                {upcomingTrackers.length >= 1 && (
                  <span className="list_title">Upcoming Tracker</span>
                )}
                {upcomingTrackers?.map((t) => (
                  <TrackerCard
                    key={t?.id}
                    name={t?.trackerName}
                    date={`${dayjs(t?.startDate).format("DD MMM")} - ${dayjs(
                      t?.endDate
                    ).format("DD MMM")}`}
                    days={`${t?.dateRange?.length} days`}
                    status="upcoming"
                    onView={() => navigate(`/app/tracker/${t.id}`)}
                    onDelete={async () => {
                      await deleteTracker(t?.id);
                      setUpcomingTrackers((prev) =>
                        prev.filter((trcaker) => trcaker?.id !== t?.id)
                      );
                    }}
                  />
                ))}
                {closedTrackers.length >= 1 && (
                  <span className="list_title">Closed Tracker</span>
                )}
                {closedTrackers?.map((t) => (
                  <TrackerCard
                    key={t?.id}
                    name={t?.trackerName}
                    date={`${dayjs(t?.startDate).format("DD MMM")} - ${dayjs(
                      t?.endDate
                    ).format("DD MMM")}`}
                    days={`${t?.dateRange?.length} days`}
                    status="closed"
                    onView={() => navigate(`/app/tracker/${t.id}`)}
                    onDelete={async () => {
                      await deleteTracker(t?.id);
                      setClosedTrackers((prev) =>
                        prev.filter((trcaker) => trcaker?.id !== t?.id)
                      );
                    }}
                  />
                ))}
                {ongoingTrackers.length < 1 &&
                  closedTrackers.length < 1 &&
                  upcomingTrackers.length < 1 && (
                    <div className="empty_component">
                      <p style={{marginBottom: "1rem"}}>
                        No tracker found.
                      </p>
                      <p>
                        Tracker keeps track of your trades over a specific time
                        period and gives you detailed analytics that can be
                        shared with your community.
                      </p>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
      <CreateTrackerModal
        onClose={() => setShowCreateTrackerModal(false)}
        visible={showCreateTrackerModal}
      />
    </>
  );
};

export default TrackersPage;

export const TrackerCard = ({
  name,
  date,
  days,
  status,
  skLoading,
  onView,
  onDelete,
}) => {
  const dropdownItems = [
    {
      label: <span onClick={() => onView()}>View details</span>,
      key: "0",
    },
    {
      label: <span onClick={() => onDelete()}>Delete Tracker</span>,
      key: "1",
    },
  ];
  return (
    <div className="tracker_card">
      <div className="title cursor-pointer" onClick={() => onView && onView()}>
        {!skLoading ? (
          <>
            <div className={`tracker_status ${status || ""}`} />
            <span>{name}</span>
          </>
        ) : (
          <>
            <SkeletonLoaderItem className="tracker_status" />
            <SkeletonLoaderItem
              style={{ width: "130px", borderRadius: "4px" }}
            />
          </>
        )}
      </div>
      <div className="date_wrapper">
        {!skLoading ? (
          <>
            <span className="date">{date}</span>
            <span className="count">{days}</span>
          </>
        ) : (
          <>
            <SkeletonLoaderItem style={{ width: "200px" }} />
          </>
        )}
      </div>
      <div className="action">
        {!skLoading ? (
          <>
            <span className="view" onClick={() => onView && onView()}>
              View details
            </span>
            <Popconfirm
              title="Delete tracker"
              description="Are you sure to delete this tracker?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <span className="delete">Delete Tracker</span>
            </Popconfirm>
          </>
        ) : (
          <>
            <SkeletonLoaderItem style={{ width: "80px" }} />
          </>
        )}
      </div>
      <div className="dropdown">
        {!skLoading ? (
          <AppDropdown dropdownItems={dropdownItems} />
        ) : (
          <>
            <SkeletonLoaderItem style={{ width: "80px" }} />
          </>
        )}
      </div>
    </div>
  );
};
