export const forexNames = {
  GBPUSD: "gbpusd",
  NZDUSD: "nzdusd",
  AUDUSD: "audusd",
  EURUSD: "eurusd",
  XAUUSD: "xauusd",
  USDJPY: "usdjpy",
  EURJPY: "eurjpy",
  GBPJPY: "gbpjpy",
  AUDJPY: "audjpy",
  CHFJPY: "chfjpy",
  CADJPY: "cadjpy",
  NZDJPY: "nzdjpy",
  USDCHF: "usdchf",
  EURCHF: "eurchf",
  AUDCHF: "audchf",
  CADCHF: "cadchf",
  USDCAD: "usdcad",
  GBPCAD: "gbpcad",
  AUDNZD: "audnzd",
  GBPAUD: "gbpaud",
  EXNESS: "exness",
  KWAKOL: "kwakol",

  EURGBP: "eurgbp",
  GBPNZD: "gbpnzd",
  GBPCHF: "gbpchf",
  EURAUD: "euraud",
  EURCAD: "eurcad",
  EURNZD: "eurnzd",
  US30: "us30",
  NAS100: "nas100",

  BTCUSD: "btcusd",
};

const indicesNames = {
  //indices
  US30: "us30",
  NAS100: "nas100",
};

const syntheticNames = {
  VOLATILITY100: "volatility 100",
  VOLATILITY75: "volatility 75",
  VOLATILITY25: "volatility 25",
};

const cryptoNames = {
  BTCUSD: "btcusd",
  BTCUSDT: "btcusdt",
  ETHUSD: "ethusd",
  ETHUSDT: "ethusdt",

};

export const { FOREXTYPE, SYNTHETICTYPE, INDICESTYPE, CRYPTOTYPE, ALLTYPE } = {
  FOREXTYPE: "forex",
  SYNTHETICTYPE: "synthetic",
  INDICESTYPE: "indices",
  CRYPTOTYPE: "crypto",
  ALLTYPE: "all",
};

const indicesArray = [
  {
    value: indicesNames.US30,
    label: indicesNames.US30.toUpperCase(),
    instrumentType: INDICESTYPE,
  },
  {
    value: indicesNames.NAS100,
    label: indicesNames.NAS100.toUpperCase(),
    instrumentType: INDICESTYPE,
  },
];

const syntheticArray = [
  {
    value: syntheticNames.VOLATILITY100,
    label: syntheticNames.VOLATILITY100.toUpperCase(),
    instrumentType: SYNTHETICTYPE,
  },
  {
    value: syntheticNames.VOLATILITY75,
    label: syntheticNames.VOLATILITY75.toUpperCase(),
    instrumentType: SYNTHETICTYPE,
  },
  {
    value: syntheticNames.VOLATILITY25,
    label: syntheticNames.VOLATILITY25.toUpperCase(),
    instrumentType: SYNTHETICTYPE,
  },
];

const cryptoPairsArray = [
  {
    value: cryptoNames.BTCUSD,
    label: cryptoNames.BTCUSD.toUpperCase(),
    instrumentType: CRYPTOTYPE,
  },
  {
    value: cryptoNames.BTCUSDT,
    label: cryptoNames.BTCUSDT.toUpperCase(),
    instrumentType: CRYPTOTYPE,
  },
  {
    value: cryptoNames.ETHUSD,
    label: cryptoNames.ETHUSD.toUpperCase(),
    instrumentType: CRYPTOTYPE,
  },
  {
    value: cryptoNames.ETHUSDT,
    label: cryptoNames.ETHUSDT.toUpperCase(),
    instrumentType: CRYPTOTYPE,
  },
];

export const currencyPairs = [
  {
    value: forexNames.GBPUSD,
    label: forexNames.GBPUSD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.EURUSD,
    label: forexNames.EURUSD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.US30,
    label: forexNames.US30.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.NZDUSD,
    label: forexNames.NZDUSD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.AUDUSD,
    label: forexNames.AUDUSD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.XAUUSD,
    label: forexNames.XAUUSD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.USDJPY,
    label: forexNames.USDJPY.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.EURJPY,
    label: forexNames.EURJPY.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.GBPJPY,
    label: forexNames.GBPJPY.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.AUDJPY,
    label: forexNames.AUDJPY.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.CHFJPY,
    label: forexNames.CHFJPY.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.CADJPY,
    label: forexNames.CADJPY.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.NZDJPY,
    label: forexNames.NZDJPY.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.USDCHF,
    label: forexNames.USDCHF.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.AUDCHF,
    label: forexNames.AUDCHF.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.CADCHF,
    label: forexNames.CADCHF.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.USDCAD,
    label: forexNames.USDCAD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.GBPCAD,
    label: forexNames.GBPCAD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.GBPAUD,
    label: forexNames.GBPAUD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.AUDNZD,
    label: forexNames.AUDNZD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.EURGBP,
    label: forexNames.EURGBP.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.GBPNZD,
    label: forexNames.GBPNZD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.GBPCHF,
    label: forexNames.GBPCHF.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.EURAUD,
    label: forexNames.EURAUD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.EURCAD,
    label: forexNames.EURCAD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
  {
    value: forexNames.EURNZD,
    label: forexNames.EURNZD.toUpperCase(),
    instrumentType: FOREXTYPE,
  },
];

// this is the culmulative collation of all the instrument categories
export const instruments = [
  ...currencyPairs,
  ...indicesArray,
  ...syntheticArray,
  ...cryptoPairsArray,
];

export const currencies = [
  {
    value: "usd",
    label: "USD",
  },
  {
    value: "ngn",
    label: "NGN",
  },
  {
    value: "gbp",
    label: "GBP",
  },
  {
    value: "zar",
    label: "ZAR",
  },
];
