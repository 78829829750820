import React, { useEffect } from 'react';
import './styles.scss';
import { useGeneralContext } from '../../context';

const AppLoading = () => {
	const {
		state: { appLoading },
	} = useGeneralContext();

	useEffect(() => {
		document.body.style.overflow = appLoading ? 'hidden' : 'auto';
		document.body.style.height = appLoading ? '100vh !important' : '100%';

		return () => {
			setTimeout(() => {
				document.body.style.overflow = 'auto';
			}, 100);
			// document.body.style.height = appLoading ? '100vh !important' : '100%';
		};
	}, [appLoading]);

	if (!appLoading) return <></>;

	return (
		<>
			{appLoading && (
				<div className="loading">
					<div className="backdrop" />
					<div className="modal">
						<div className="loader" />
					</div>
				</div>
			)}
		</>
	);
};

export default AppLoading;
