import { Drawer } from 'antd';
import { closeCircle } from '../../assets/svg';
import './styles.scss';
import { useState } from 'react';
import dayjs from 'dayjs';
import AppModal from '../appModal';

const tabs = [
	{ title: 'Payout Request', value: 'req' },
	{ title: 'Proccessed Transactions', value: 'all' },
];

const PayoutDrawer = ({ visible, onClose, processedTrx = [], payoutReq = [] }) => {
	const [activeTab, setActiveTab] = useState('req');
	const [payoutAccount, setPayoutAccount] = useState(null);
	return (
		<>
			<Drawer placement="right" closeIcon={closeCircle} width="457px" className="payout_drawer" onClose={() => onClose()} open={visible}>
				<div className="tabs_wrapper">
					{tabs.map((val) => (
						<div className={`tab ${val.value === activeTab ? 'active' : ''}`} key={val.value} onClick={() => setActiveTab(val.value)}>
							{val.title}
						</div>
					))}
				</div>
				<div className="trx_list">
					{activeTab === 'req' && (
						<>
							{payoutReq.map((val) => (
								<div className="trx">
									<div className="date">
										Request date: <span>{dayjs(val?.createdAt?.toDate()).format('DD MMM. YYYY | hh:mmA')}</span>
									</div>
									<div className="info">{val?.name} requested for payout.</div>
									<div className="actions">
										<span
											onClick={() =>
												setPayoutAccount({
													accountName: val?.accountName,
													accountNumber: val?.accountNumber,
													bankName: val?.bankName,
												})
											}
										>
											View account details
										</span>
										<span>Payout Processed</span>
									</div>
								</div>
							))}
							{payoutReq.length < 1 && (
								<div className="empty_state">
									<span>No pending request.</span>
								</div>
							)}
						</>
					)}
					{activeTab === 'all' && (
						<>
							{processedTrx.map(() => (
								<div className="trx">
									<div className="date">
										Request date: <span>12 Apr. 2024 | 2.00pm</span>
									</div>
									<div className="info">You Processed $100 payout request for Habib Wahab</div>
									<div className="date">
										Process date: <span>12 Apr. 2024 | 2.00pm</span>
									</div>
								</div>
							))}
							{processedTrx.length < 1 && (
								<div className="empty_state">
									<span>No Data.</span>
								</div>
							)}
						</>
					)}
				</div>
			</Drawer>
			<AppModal style={{ zIndex: '1' }} width="509px" footer={false} closable={false} open={payoutAccount} title="Account Details" onCancel={() => setPayoutAccount(null)}>
				<div className="request_payout">
					<div className="payout_account_details">
						<table>
							<tr>
								<td>Account Holder:</td>
								<td>{payoutAccount?.accountName}</td>
							</tr>
							<tr>
								<td>Account number:</td>
								<td>{payoutAccount?.accountNumber}</td>
							</tr>
							<tr>
								<td>Bank name:</td>
								<td>{payoutAccount?.bankName}</td>
							</tr>
						</table>
					</div>
				</div>
			</AppModal>
		</>
	);
};

export default PayoutDrawer;
