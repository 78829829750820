import { useFormik } from 'formik';
import AppModal from '../appModal';
import CustomInput from '../forms/Input';
import * as Yup from 'yup';
import './styles.scss';
import ButtonComponent from '../Button/button';
import { useEffect, useState } from 'react';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import { addPayoutAcount, PAYOUTACCOUNT, updateDocument } from '../../firebase/firebaseFunctions';

const AddAccountModal = ({ visible, onClose, accountDetails }) => {
	const { user } = useAuthContext();
	const { showErrorMessage, showSuccessMessage } = useNotificationContext();

	const [isLoading, setIsLoading] = useState(false);
	const onSubmit = async (val) => {
		setIsLoading(true);
		try {
			if (accountDetails?.id) {
				await updateDocument(PAYOUTACCOUNT, accountDetails.id, val);
				onClose({ id: accountDetails.id, ...val });
			} else {
				let res = await addPayoutAcount({ ...val, userId: user?.uid });
				showSuccessMessage('Account Updated');
				onClose({ id: res.id, ...val });
			}
		} catch (error) {
			showErrorMessage(error.message);
		} finally {
			setIsLoading(false);
		}
	};
	const { handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue } = useFormik({
		initialValues: {
			accountName: '',
			bankName: '',
			accountNumber: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			accountName: Yup.string().required().label('Account name'),
			bankName: Yup.string().required().label('Account name'),
			accountNumber: Yup.string()
				.min(10)
				.test((value, schema) => {
					const isNumber = Number(value);
					if (!isNumber) return schema.createError({ message: 'Invalid account number' });
					return schema;
				})
				.required()
				.label('Account number'),
		}),
		onSubmit,
	});

	useEffect(() => {
		setFieldValue('accountName', accountDetails?.accountName);
		setFieldValue('accountNumber', accountDetails?.accountNumber);
		setFieldValue('bankName', accountDetails?.bankName);
	}, [accountDetails]);

	return (
		<AppModal width="509px" footer={false} closable={false} open={visible} title={accountDetails?.id ? 'Update Payout Account' : 'Add Payout Account'} onCancel={() => onClose()}>
			<div className="add_account_modal">
				<span className="info">{accountDetails?.id ? 'Update your NGN' : 'Add an NGN'} account where you can receive payout</span>
				<CustomInput
					label="Account name"
					placeholder="Enter account name"
					name="account name"
					className="mar-b-28"
					onChange={handleChange('accountName')}
					errorVisible={touched['accountName']}
					errorMessage={errors['accountName']}
					onBlur={handleBlur('accountName')}
					value={values.accountName}
				/>
				<CustomInput
					label="Bank Name"
					placeholder="Enter bank Name"
					name="bank name"
					className="mar-b-28"
					onChange={handleChange('bankName')}
					errorVisible={touched['bankName']}
					errorMessage={errors['bankName']}
					onBlur={handleBlur('bankName')}
					value={values.bankName}
				/>
				<CustomInput
					label="Account number"
					placeholder="Enter account number"
					name="account number"
					type="text"
					className="mar-b-28"
					onChange={handleChange('accountNumber')}
					errorVisible={touched['accountNumber']}
					errorMessage={errors['accountNumber']}
					onBlur={handleBlur('accountNumber')}
					value={values.accountNumber}
				/>
				<ButtonComponent loading={isLoading} buttonText="Save" className="button secondary w-full" onClick={() => handleSubmit()} />
			</div>
		</AppModal>
	);
};

export default AddAccountModal;
