import { darkIcon, hamburgerIcon, lightIcon, logoutIcon } from '../../assets/svg';
import { Switch, Dropdown } from 'antd';
import './styles.scss';
import { useGeneralContext } from '../../context';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import SkeletonLoaderItem from '../../Components/skeletonLoader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {getAuth} from "firebase/auth"
const DashboardHeader = ({ skLoading }) => {
	const auth = getAuth();
	const authUserProfile = auth.currentUser;
	const navigate = useNavigate();
	const {
		state: { isDarkModeTheme, sidebarOpen },
		toggleTheme,
		toggleSidebarOpen,
	} = useGeneralContext();

	const { logOut, user, loading: userDataLoading } = useAuthContext();

	const handleLogOut = async () => {
		await logOut();
		navigate('/signin');
	};
	const items = [
		{
			label: <Switch className="theme" checked={!isDarkModeTheme} onChange={toggleTheme} checkedChildren={lightIcon} unCheckedChildren={darkIcon} />,
			key: '1',
		},
		{
			type: 'divider',
		},
		{
			label: (
				<div className="m_logout" onClick={() => handleLogOut()}>
					{logoutIcon}
					<span>Log out</span>
				</div>
			),
			key: '0',
		},
	];

	useEffect(() => {
		if (!userDataLoading && !user) {
			navigate('/signin');
		}
	}, [user]);
	return (
		<>
			<div className="dashboard_header">
				{!skLoading && (
					<>
						<span className="dash_icon" onClick={toggleSidebarOpen}>
							{hamburgerIcon}
						</span>

						<div className="app_logo">
							{isDarkModeTheme ? <img src="/images/logo-dark.png" alt="logo" /> : <img src="/images/logo.png" alt="logo" />}
							<span>Spillmetrics</span>
						</div>
						<div className="greeting">
							<span>Welcome,</span> {user?.displayName?.split(' ')[0]}
						</div>
						<div className="aside">
							<Switch className="theme" checked={!isDarkModeTheme} onChange={toggleTheme} checkedChildren={lightIcon} unCheckedChildren={darkIcon} />
							<div className="user">
								{authUserProfile?.photoURL ? <img src={authUserProfile?.photoURL} className="user_image" /> : <div className="no_profile" />}
								<div className="details">
									<span>{user?.displayName}</span>
									<span>{user?.email}</span>
								</div>
							</div>
							<span className="cursor-pointer" onClick={() => handleLogOut()}>
								{logoutIcon}
							</span>
						</div>

						<div className="dropdown_mobile">
							<Dropdown menu={{ items }} trigger={['click']}>
								<div className="drop">
									<div className="user">{user?.photoURL ? <img src={user?.photoURL} className="user_image" /> : <div className="no_profile" />}</div>
									<svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
										<path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
									</svg>
								</div>
							</Dropdown>
						</div>
					</>
				)}
				{skLoading && (
					<>
						<span className="dash_icon" onClick={toggleSidebarOpen}>
							{hamburgerIcon}
						</span>

						<div className="app_logo">
							{isDarkModeTheme ? <img src="/images/logo-dark.png" alt="logo" /> : <img src="/images/logo.png" alt="logo" />}
							<span>Spillmetrics</span>
						</div>
						<SkeletonLoaderItem className="sk_greetings" width="100%" maxWidth="200px" />
						<div className="aside">
							<Switch className="theme" checked={!isDarkModeTheme} onChange={toggleTheme} checkedChildren={lightIcon} unCheckedChildren={darkIcon} />
							<div className="user">
								<SkeletonLoaderItem width="40px" height="40px" style={{ borderRadius: '50%' }} />
								<div className="details">
									<SkeletonLoaderItem width="60px" />
									<SkeletonLoaderItem width="100px" />
								</div>
							</div>
							<SkeletonLoaderItem width="30px" />
						</div>
						<SkeletonLoaderItem className="m_sk_user" width="40px" height="40px" style={{ borderRadius: '50%' }} />
					</>
				)}
			</div>
		</>
	);
};

export default DashboardHeader;
