import { Modal } from 'antd';
import { closeCircle } from '../../assets/svg';
import './styles.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextArea from '../forms/TextArea';
import { useState } from 'react';
import ButtonComponent from '../Button/button';
import dayjs from 'dayjs';

const NoTradeMoalReason = ({ onClose, visible, saveReason, missedDate }) => {
	const [loading, setLoading] = useState(false);
	const { handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm } = useFormik({
		initialValues: {
			reason: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			reason: Yup.string().test((value, schema) => {
				const _words = String(value).split(' ');
				if (_words.length < 5) return schema.createError({ message: 'Reason should be at least 5 words' });
				if (_words.length > 1000) return schema.createError({ message: 'Reason should not be more than 1000 words' });

				return schema;
			}),
		}),
		onSubmit: async (val) => {
			setLoading(true);
			await saveReason(val?.reason);
			resetForm();
			setLoading(false);
		},
	});
	return (
		<Modal footer={false} closable={false} className="custom_modal" width="480px" open={visible} onCancel={() => onClose()}>
			<div className="no_trade_modal">
				<div className="top">
					<div className="header">
						<h4>Why didn’t you trade {missedDate ? (dayjs(missedDate).isSame(dayjs(), 'day') ? 'today' : `on ${missedDate}`) : 'today'}</h4>
						{/* <span>You can select more than one reason</span> */}
					</div>
					<span className="cursor-pointer" onClick={() => onClose()}>
						{closeCircle}
					</span>
				</div>
				<TextArea
					label="Reason"
					className="w-full"
					onChange={handleChange('reason')}
					errorVisible={touched['reason']}
					errorMessage={errors['reason']}
					onBlur={handleBlur('reason')}
					value={values.reason}
				/>
				<ButtonComponent buttonText="Save" className="button secondary" buttonWidth="100%" loading={loading} onClick={() => handleSubmit()} />
			</div>
		</Modal>
	);
};

export default NoTradeMoalReason;
