import { DatePicker } from 'antd';
import React from 'react';
import './styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { notificationVariant } from '../../utils/helpers';
import dayjs from 'dayjs';

const DateInput = ({ className, disabled, onChange, disabledDate, placeholder, dateFormat, value, label, errorMessage, errorVisible, onBlur }) => {
	return (
		<div className={`custom_input ${className || ''}`}>
			<label>{label}</label>
			<DatePicker
				className={`custom_date_picker  ${errorVisible && errorMessage ? 'error' : ''}`}
				// bordered={false}
				onBlur={onBlur}
				disabled={disabled}
				style={{ width: '100%', height: '100%' }}
				placeholder={placeholder}
				format={dateFormat || 'YYYY-MM-DD'}
				disabledDate={disabledDate}
				onChange={(val) => {
					if (val === null) return;
					onChange && onChange(dayjs(val).format(dateFormat || 'YYYY-MM-DD'));
				}}
				value={value ? dayjs(value) : undefined}
			/>
			<AnimatePresence>
				{errorVisible && errorMessage && (
					<motion.div variants={notificationVariant} initial="hidden" animate="visible" exit="exit" className="error_message">
						<small>{errorMessage}</small>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default DateInput;
