// NotificationProvider.js
import { useContext, } from "react";
import PropTypes from "prop-types";
import { NofificationContext } from "./NotificationContext";
import { notification } from 'antd';



const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const showSuccessMessage = (description, message = 'Success') => showMessage(message, description, 'success');
  const showInfoMessage = (description, message = 'Information') => showMessage(message, description, 'info');
  const showWarningMessage = (description, message = 'Warning') => showMessage(message, description, 'warning');
  const showErrorMessage = (description, message = 'Error') => showMessage(message, description, 'error');


  const showMessage = (message, description, type) => {

    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <NofificationContext.Provider value={{ showSuccessMessage, showInfoMessage, showWarningMessage, showErrorMessage }}>
      {contextHolder}
      {children}
    </NofificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNotificationContext = () => {
  const allContextValues = useContext(NofificationContext);
  return {
    ...allContextValues,
  };
};

export default NotificationProvider;