import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import React from 'react';
import { capitalizeFirstLetter } from '../../utils/helpers';

const AppLineChat = ({ showLines, data }) => {
	return (
		<div style={{ width: '100%', height: 320 }}>
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					width={500}
					height={300}
					data={data}
					// margin={{
					// 	top: 5,
					// 	right: 30,
					// 	left: 20,
					// 	bottom: 5,
					// }}
				>
					{showLines && (
						<>
							<XAxis dataKey="name" />
							<YAxis allowDecimals={false} />
						</>
					)}
					<Tooltip formatter={(value, name) => [value, capitalizeFirstLetter(name)]} />
					<Line type="monotone" dataKey="losses" strokeWidth={3} stroke="#f85454" dot={{ r: 0 }} />
					<Line type="monotone" dataKey="wins" strokeWidth={3} stroke="#217eec" dot={{ r: 0 }} />
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default AppLineChat;
