import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";
import { Dropdown, Switch } from "antd";
import TradelineLight from "../../../TradelineLight.png";
import TradelineDark from "../../../TradelineDark.png";
import ButtonComponent from "../../../Components/Button/button";
import { useGeneralContext } from "../../../context";
import { useAuthContext } from "../../../context/auth-context/AuthProvider";
import { darkIcon, lightIcon, logoutIcon } from "../../../assets/svg";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import SkeletonLoaderItem from "../../../Components/skeletonLoader";
import {getAuth} from "firebase/auth"

const TopNav = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const auth = getAuth();
	const authUserProfile = auth.currentUser;

  const onMenuClick = () => {
    setIsMenuOpened(!isMenuOpened);
  };
  const {
    state: { isDarkModeTheme },
    toggleTheme,
  } = useGeneralContext();

  const { user, loading: userDataLoading, logOut } = useAuthContext();

  const items = [
    {
      label: (
        <Switch
          className="theme"
          checked={!isDarkModeTheme}
          onChange={toggleTheme}
          checkedChildren={lightIcon}
          unCheckedChildren={darkIcon}
        />
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <NavLink to="/app/overview" className="navlinks" id="">
          Dashboard
        </NavLink>
      ),
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className="m_logout" onClick={logOut}>
          {logoutIcon}
          <span>Log out</span>
        </div>
      ),
      key: "0",
    },
  ];

  return (
    <>
      <div className="landing_nav">
        <div
          className="main_wrapper"
          id={isMenuOpened ? "main_wrapper_expandable" : ""}
        >
          <div className="flex_container">
            <div className="flex_1">
              <NavLink to="/" className="navlinks">
                <div className="app_logo">
                  {isDarkModeTheme ? (
                    <img src="/images/logo-dark.png" alt="logo" />
                  ) : (
                    <img src="/images/logo.png" alt="logo" />
                  )}
                  <span>Spillmetrics</span>
                </div>
              </NavLink>
            </div>
            <div className="flex_2 mid_nav_items">
              <ul>
                <li>
                  <a href="#features" className="navlinks" id="login-text">
                    Features
                  </a>
                </li>
                <li>
                  <NavLink to="/pricing" className="navlinks" id="login-text">
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" className="navlinks" id="login-text">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex_2">
              {userDataLoading ? (
                <>
                  <div className="aside_mobile">
                    <SkeletonLoaderItem
                      width="40px"
                      height="40px"
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <div className="aside">
                    <div className="user">
                      <SkeletonLoaderItem
                        width="40px"
                        height="40px"
                        style={{ borderRadius: "50%" }}
                      />
                      <div className="details">
                        <SkeletonLoaderItem width="60px" />
                        <SkeletonLoaderItem width="100px" />
                      </div>
                    </div>
                    <SkeletonLoaderItem width="30px" />
                  </div>
                </>
              ) : (
                <div>
                  {!userDataLoading && user ? (
                    <div>
                      <div className="aside">
                        <Switch
                          className="theme"
                          checked={!isDarkModeTheme}
                          onChange={toggleTheme}
                          checkedChildren={lightIcon}
                          unCheckedChildren={darkIcon}
                        />
                        <NavLink to="/app/overview" className="navlinks">
                          <div className="user">
                            {authUserProfile?.photoURL ? (
                              <img
                                src={authUserProfile?.photoURL}
                                className="user_image"
                              />
                            ) : (
                              <div className="no_profile" />
                            )}
                            <div className="details">
                              <span>{user?.displayName}</span>
                              <span>{user?.email}</span>
                            </div>
                          </div>
                        </NavLink>
                        <span className="cursor-pointer" onClick={logOut}>
                          {logoutIcon}
                        </span>
                      </div>

                      <div className="landing_dropdown_mobile">
                        <Dropdown menu={{ items }} trigger={["click"]}>
                          <div className="drop">
                            <div className="user">
                              {user?.photoURL ? (
                                <img
                                  src={user?.photoURL}
                                  className="user_image"
                                />
                              ) : (
                                <div className="no_profile" />
                              )}
                            </div>
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="down"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                            </svg>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  ) : (
                    <ul>
                      <li>
                        <NavLink
                          to="/signin"
                          className="navlinks"
                          id="login-text"
                        >
                          Login
                        </NavLink>
                      </li>
                      <li style={isMenuOpened ? { display: "none" } : {}}>
                        <NavLink to="/signup" className="navlinks">
                          <ButtonComponent
                            buttonText="Sign up"
                            id="landing_btn"
                          />
                        </NavLink>
                      </li>
                      {isMenuOpened ? (
                        <li className="menu_icon" onClick={onMenuClick}>
                          <CloseOutlined />
                        </li>
                      ) : (
                        <li className="menu_icon" onClick={onMenuClick}>
                          <MenuOutlined />
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className="mobile_nav_options"
            style={!isMenuOpened ? { display: "none" } : {}}
          >
            <hr />
            <ul>
              <li>
                <a href="#features" className="navlinks" id="login-text">
                  Features
                </a>
              </li>
              <li>
                <NavLink to="/pricing" className="navlinks" id="login-text">
                  Pricing
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="navlinks" id="login-text">
                  Contact
                </NavLink>
              </li>
              <li>
                <NavLink to="/signup" className="navlinks">
                  <ButtonComponent
                    buttonText="Sign up"
                    className="mobile_menu_signup_btn"
                    id="landing_btn"
                  />
                </NavLink>
              </li>
              <li>
                <NavLink to="/signup" className="navlinks">
                  <ButtonComponent
                    buttonText="Log in"
                    className="mobile_menu_login_btn"
                    id="landing_btn"
                  />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Switch
        checked={!isDarkModeTheme}
        onChange={toggleTheme}
        checkedChildren={lightIcon}
        unCheckedChildren={darkIcon}
        className="landing-theme_switcher"
      />
    </>
  );
};

export default TopNav;
