import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const scrollPageToTop = () => {
	window.scrollTo({
		top: 0,
		left: 0,
	});
};

export const notificationVariant = {
	hidden: {
		bottom: '-40px',
		opacity: 0,
	},
	visible: {
		bottom: '-20px',
		opacity: 1,
		transition: { duration: 0.15 },
	},
	exit: {
		bottom: '-40px',
		opacity: 0,
		transition: {
			duration: 0.15,
			ease: 'easeInOut',
			transitionEnd: {
				display: 'none',
			},
		},
	},
};

export const getTodaysDate = () => new Date().toISOString().slice(0, 10);

export const isNull = (value) => value === null || value === undefined || value === '' || value === 'null';


export const generateRandomId = () => {
	const timestamp = new Date().getTime().toString(36);
	const randomNumber = Math.random().toString(36).substr(2, 5);
	return timestamp + randomNumber;
}

export const isValidFirebaseImageLink = (link) => {
	// Define a regex pattern for Firebase Storage image URL
	const firebaseStoragePattern = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/[a-zA-Z0-9-]+\.appspot\.com\/o\/.*\?alt=media/;

	// Check if the link matches the pattern
	return firebaseStoragePattern.test(link);
}

export const dateFallsInCurrentWeek = (date) => {
	const today = dayjs();
	const firstSunday = today.startOf('week');
	const lastSaturday = today.endOf('week');
	return date >= firstSunday && date <= lastSaturday
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generateDateRangeForTracker = (startDate, numberOfDays, includeWeekends) => {
	let start = dayjs(startDate);
	let currentDate = start;
	const datesArray = [];
	let daysAdded = 0;

	while (daysAdded < numberOfDays) {
		if (!includeWeekends && (currentDate.day() === 0 || currentDate.day() === 6)) {
			// Skip weekends if includeWeekends is false
			currentDate = currentDate.add(1, 'day');
			continue;
		}
		datesArray.push({ status: '', reasonMissed: '', date: currentDate.format('YYYY-MM-DD') });
		currentDate = currentDate.add(1, 'day');
		daysAdded++;
	}

	return datesArray
};


export const getTrackerStatus = (startDate, endDate) => {
	if (dayjs(endDate).isBefore(dayjs(), 'day')) {
		return 'closed'
	}
	if (dayjs(startDate).isAfter(dayjs(), 'day')) {
		return 'upcoming'
	}
	return 'ongoing'
}

export const getTrackeCurrentrDay = (dateRange, weekend) => {
	if (!Array.isArray(dateRange)) return
	let dateRangeIndex = Array.from(dateRange).findIndex(v => dayjs().isSame(dayjs(v?.date), 'day'))

	if (dayjs().isBefore(dayjs(dateRange[0].date), 'day')) {
		return "Upcoming"
	}
	if (dayjs().isAfter(dayjs(dateRange[dateRange.length - 1].date), 'day')) {
		return "Closed"
	}
	if (weekend === false) {
		if (dayjs().day() === 6 || dayjs().day() === 0) {
			return "Weekend Break"
		}
	}

	if (dateRangeIndex >= 0) {
		return `Day ${dateRangeIndex + 1}`
	}
}

export const getDayDoneMissedLeft = (dateRange) => {
	if (!Array.isArray(dateRange)) return
	let done = 0
	let missed = 0
	let left = 0
	Array.from(dateRange).forEach(v => {
		if (dayjs(v.date).isBefore(dayjs(), 'day')) {
			if (v.status === 'done') {
				done++
				return
			}
		}
		if (dayjs(v.date).isSameOrBefore(dayjs(), 'day')) {
			if (v?.status === "missed") {
				missed++
				return
			}
		}
		if (dayjs(v.date).isBefore(dayjs(), 'day')) {
			if (v?.status === "") {
				missed++
				return
			}
		}
	})
	let dateRangeIndex = Array.from(dateRange).findIndex(v => dayjs().isSame(dayjs(v?.date), 'day'))
	if (dateRangeIndex >= 0) {
		left = dateRange.length - (dateRangeIndex + 1)
	}

	return {
		done, missed, left
	}
}

export const getPercentageValue = (count = 0, total = 0) => {
	return ((Number(count) * 100) / Number(total) || 0).toFixed(1);
}