import React, { useEffect, useState } from 'react';
import './landingPage.scss';
import TopNav from './Sections/LandingNav';
import ButtonComponent from '../../Components/Button/button';
import WhyUs from './Sections/whyUs';
import TestimonialComponent from './Sections/testimonial';
import Img from '../../images/adaeze.png';
import { CurvyArrow } from '../../assets/svg';
import DashboardviewSvg from '../../assets/dashboardviewSvg';
import FooterComponent from '../../Components/footer/footer';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAuthContext } from '../../context/auth-context/AuthProvider';

const LandingPage = () => {
	const [isIos, setIsIos] = useState(undefined);
	const { user, loading: userDataLoading } = useAuthContext();
	const navigate = useNavigate();

	let deferredPrompt;

	useEffect(() => {
		if ('serviceWorker' in navigator && 'PushManager' in window) {
			window.addEventListener('beforeinstallprompt', (e) => {
				e.preventDefault();
				deferredPrompt = e;
			});
			// console.log('here: ', deferredPrompt);
		}
	}, []);

	const onDownloadClick = async () => {
		try {
			if (deferredPrompt !== null) {
				deferredPrompt.prompt();
				const { outcome } = await deferredPrompt.userChoice;
				if (outcome === 'accepted') {
					deferredPrompt = null;
				}
			}
		} catch (error) {
			// console.log(error);
		}
	};

	useEffect(() => {
		if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {
			setIsIos(true);
		} else {
			setIsIos(false);
		}
	}, []);

	useEffect(() => {
		if (!userDataLoading && user?.emailVerified) {
			navigate('/app/overview');
		}
		// eslint-disable-next-line
	}, [user, userDataLoading]);

	return (
		<div className="landing-page_container">
			<TopNav />
			{/* <div className="green_blur_shadow">
        <GreenBlurShadow />
      </div> */}
			<div className="hero-section">
				<div className="fixed-curvy-arrow">
					<CurvyArrow />
				</div>
				<p className="hero-head_text">Your Path to Trading Mastery Begins Here</p>
				<p className="hero-sub_text">
					Welcome to your ultimate companion on the journey to trading success. <br />
					Journal trade, refine strategy and make informed decision.
				</p>
				<div className="hero-btn_container">
					<NavLink to="/pricing" className="navlinks">
						<ButtonComponent buttonText="Get Started" buttonWidth="12rem" bgcolor="#8CFBDE" textColor="#000" />
					</NavLink>
					{isIos ? <span></span> : <ButtonComponent buttonText="Download App" id="landing_btn" buttonWidth="12rem" onClick={onDownloadClick} />}
				</div>
				<DashboardviewSvg className="hero-img svg-img" />
			</div>
			<div className="rest_of_body">
				<div className="quote-container">
					<div className="flex-1">
						<p>Built for and trusted by people that wants to stay ahead</p>
						<div className="vertical-separator"></div>
					</div>
					<div className="flex-2">
						<p className="quote">
							As a beginner, I appreciate how Spillmetrics has helped me understand my trades better. It's like a mentor guiding me through the complexities of the market
						</p>
						<div className="person-detail">
							<div className="person-flex_1">
								<img src={Img} alt="" />
							</div>
							<div className="person-flex_2">
								<p className="name">Adaeze Don</p>
							</div>
						</div>
					</div>
				</div>
				<div id="features">
					<WhyUs />
				</div>
				{/* <TestimonialComponent /> */}
			</div>
			<FooterComponent />
		</div>
	);
};

export default LandingPage;
