import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { instruments, FOREXTYPE, INDICESTYPE, CRYPTOTYPE, SYNTHETICTYPE, ALLTYPE } from './data';
import { notificationVariant } from '../../utils/helpers';
import './styles.scss';

const tagsArray = [
	{
		value: ALLTYPE,
		label: 'All',
	},
	{
		value: FOREXTYPE,
		label: 'Forex',
	},
	{
		value: INDICESTYPE,
		label: 'Indices',
	},
	{
		value: SYNTHETICTYPE,
		label: 'Synthetic',
	},
	{
		value: CRYPTOTYPE,
		label: 'Crypto',
	},
];
const InstrumentWidget = ({ title, onChange, value, className, errorVisible, errorMessage }) => {
	const [tagSelected, setTagSelected] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [modalVisible, setModalVisible] = useState(false);

	const selectTag = (value) => {
		setModalVisible(true);
		if (value === ALLTYPE) {
			setTagSelected('');
			return;
		}
		if (tagSelected === value) {
			setTagSelected('');
			return;
		}
		setTagSelected(value);
		// console.log(tagSelected);
	};

	const filteredInstruments = instruments.filter((instrument) => {
		if (tagSelected == '' && inputValue == '') {
			return instruments;
		}
		if (tagSelected) {
			return instrument.instrumentType === tagSelected;
		}
		return instrument.value.toLowerCase().includes(inputValue?.toLowerCase());
	});

	return (
		<div className={`instrument-select_component ${className || ''}`}>
			<p>{title}</p>
			<input
				type="text"
				value={inputValue.toUpperCase()}
				onChange={(e) => {
					setInputValue(e.target.value.toUpperCase());
					// console.log(inputValue);
				}}
				onFocus={() => setModalVisible(true)}
				placeholder="Select instrument"
				className={errorVisible && errorMessage ? 'error' : ''}
			/>
			<div className="instrument_modal" id={modalVisible ? 'show_modal' : ''}>
				<div className="tags_container">
					{tagsArray.map(({ value, label }) => (
						<div className="tag" id={tagSelected === value ? 'tag_active' : ''} onClick={() => selectTag(value)}>
							{label}
						</div>
					))}
				</div>
				<div className="instruments_inner_container">
					{filteredInstruments.map((value) => (
						<p
							className="instrument"
							onClick={() => {
								onChange(value.value);
								setInputValue(value.value);
								setModalVisible(false);
							}}
						>
							{value.label}
						</p>
					))}
				</div>
			</div>
			<AnimatePresence>
				{errorVisible && errorMessage && (
					<motion.div variants={notificationVariant} initial="hidden" animate="visible" exit="exit" className="error_message">
						<small>{errorMessage}</small>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default InstrumentWidget;
