import { closeCircle } from '../../assets/svg';
import { Drawer, Image } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import TradeStatus from '../tradeStatus';
import './styles.scss';
import dayjs from 'dayjs';

const JournaDetailsDrawer = ({ visible, journalObject, onClose }) => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Drawer placement="right" closeIcon={closeCircle} width="457px" className="trade_details_drawer" onClose={() => onClose()} open={visible}>
			<div className="top">
				<div className="currency">
					<span>{journalObject?.currencyPair}</span>
					<span>Created on: {dayjs(journalObject?.timeStamp?.toDate()).format('DD MMM YYYY')}</span>
				</div>
				{journalObject?.tradeOutcome && <TradeStatus status={journalObject?.tradeOutcome} />}
			</div>
			{journalObject?.imageSrc ? (
				<Image
					src={journalObject?.imageSrc}
					width="100%"
					height="190px"
					className="tarde_image"
					preview={{
						toolbarRender: () => null,
					}}
				/>
			) : (
				<div className="tarde_image_placeholder" />
			)}

			<div className="trade_details_wrapper">
				<span>Trade details</span>
				<div className="details">
					<div className="single_detail">
						<span>Order type:</span>
						<span>{journalObject?.orderType}</span>
					</div>
					<div className="single_detail">
						<span>Entry Price:</span>
						<span>{journalObject?.entryPrice}</span>
					</div>
					<div className="single_detail">
						<span>Lot size:</span>
						<span>{journalObject?.lotSize} unit</span>
					</div>
					<div className="single_detail">
						<span>Stop loss:</span>
						<span>{journalObject?.stopLoss || '-'}</span>
					</div>
					<div className="single_detail">
						<span>Take profit:</span>
						<span>{journalObject?.takeProfit || '-'}</span>
					</div>
					<div className="single_detail">
						<span>Status:</span>
						<span>{journalObject?.status}</span>
					</div>
				</div>
			</div>

			<div className="reason">
				<span>Reason for trade</span>
				<p>{journalObject?.reason}</p>
			</div>
			<div className="comment">
				<span>General comment</span>
				<p>{journalObject?.generalComment || 'No Comments'}</p>
			</div>

			{location.pathname.includes('/app') && (
				<button className="button secondary" onClick={() => navigate(`/app/journals/update/${journalObject?.id}`)}>
					Update
				</button>
			)}
		</Drawer>
	);
};

export default JournaDetailsDrawer;
