import { useEffect, useState } from "react";
import { closeCircle, editPencil } from "../../../assets/svg";
import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Popconfirm } from "antd";
import "./styles.scss";
import PasswordInput from "../../../Components/forms/passwordInput";
import CustomInput from "../../../Components/forms/Input";
import VerificationInput from "react-verification-input";
import {
  createConfluence,
  getConfluences,
  deleteConfluence,
} from "../../../firebase/firebaseFunctions";
import { useAuthContext } from "../../../context/auth-context/AuthProvider";
import { useNotificationContext } from "../../../context/notification-context/NotificationProvider";
import ConfluenceModal from "./modals/confluenceModal";
import placeHolderImg from "../../../images/placeholderImg.jpg";
import { getAuth, updateProfile } from "firebase/auth";
import firebase from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const { ACCOUNT_SETTINGS, TRADE_SETTINGS } = {
  ACCOUNT_SETTINGS: "account setting",
  TRADE_SETTINGS: "trade_settings",
};
const SettingPage = () => {
  const [profileImage, setProfileImage] = useState();
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
  const [openAddConfluenceModal, setOpenAddConfluenceModal] = useState(false);
  const [emailChangeStep, setEmailChangeStep] = useState("email");
  const [settingsTab, setSettingsTab] = useState(ACCOUNT_SETTINGS);
  const [confluencesData, setConfluencesData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const { showErrorMessage, showSuccessMessage } = useNotificationContext();
  const { user } = useAuthContext();
  const auth = getAuth();
  const authUserProfile = auth.currentUser;

  const backToSettings = () => {
    setOpenChangeEmailModal(false);
    setEmailChangeStep("email");
  };

  const onSwitchTab = (tab) => {
    setSettingsTab(tab);
  };

  const getConfluencesFunc = async () => {
    try {
      const data = await getConfluences(user?.uid);
      setConfluencesData(data);
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setDataLoading(false);
    }
  };

  const deleteConfluenceFunc = async (confluenceId) => {
    try {
      await deleteConfluence(confluenceId);
      showSuccessMessage("Confluence Deleted!");
      setDataLoading(true);
    } catch (err) {
      showErrorMessage(err?.message);
    }
  };


  // Upload file and update profile photo
  const handleUpload = async (passedImg) => {
    setDataLoading(true)
    const storage = getStorage();
    const profileImageRef = ref(storage, `profile-images/${user.uid}.jpg`);
    try {
      const snapshot = await uploadBytes(profileImageRef, passedImg);
      const downloadURL = await getDownloadURL(snapshot.ref);
      await updateProfile(authUserProfile, {
        photoURL: downloadURL,
      });
      setDataLoading(false);
      showSuccessMessage("Profile photo changed");
    } catch (err) {
      console.error("Error uploading image:", err);
    }
  };

  useEffect(() => {
    getConfluencesFunc();
    console.log("user: ", authUserProfile);
  }, [dataLoading]);

  return (
    <>
      <div className="settings_flex">
        <div className="flex_1">
          <p
            id={settingsTab === ACCOUNT_SETTINGS && "active"}
            onClick={() => onSwitchTab(ACCOUNT_SETTINGS)}
          >
            Account Settings
          </p>
          <p
            id={settingsTab === TRADE_SETTINGS && "active"}
            onClick={() => onSwitchTab(TRADE_SETTINGS)}
          >
            Trade Settings
          </p>
        </div>
        <div className="flex_2">
          <div className="settings_page">
            {settingsTab == ACCOUNT_SETTINGS && (
              <div className="profile_wrapper">
                <div className="profile_picture_wrapper">
                  <span>Profile Picture</span>
                  <div className="profile_picture">
                    {!profileImage && !authUserProfile.photoURL && (
                      <img src={placeHolderImg} alt="profile visual of user" />
                    )}
                    {profileImage ? (
                      <img
                        src={URL.createObjectURL(profileImage)}
                        alt="profile visual of user"
                      />
                    ) : authUserProfile?.photoURL ? (
                      <img
                        src={authUserProfile?.photoURL}
                        alt="profile visual of user"
                      />
                    ) : (
                      ""
                    )}
                    <label htmlFor="profile_image">{editPencil}</label>
                    <input
                      id="profile_image"
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => {
                        if (!e.target.files) return;
                        if (e.target.files[0].type.includes("image")) {
                          setProfileImage(e.target.files[0]);
                        }
                        handleUpload(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>

                {/* <div className="sec_header">
									<span>Email Address</span>
									<button onClick={() => setOpenChangeEmailModal(true)}>Change</button>
								</div> */}
                <span className="current_email">
                  Your current Email address is <span>{user?.email}</span>
                </span>
                {/* I removed this for now because it's not working and I don't know that it's
				really needed because you can user the 'forgot password' in login to change password */}

                {/* <div className="sec_header">
                  <span>Password</span>
                  <button onClick={() => setOpenChangePasswordModal(true)}>
                    Change
                  </button>
                </div> */}
              </div>
            )}
            {settingsTab == TRADE_SETTINGS && (
              <div className="trade_settings">
                <div className="flex-1 flex-item">
                  <p className="heading">Trade Confluence</p>
                  <p className="description">
                    A trade confluence is a sign that show a good chance to
                    trade. They are signs you want to see in your trading chart
                    before taking a trade{" "}
                  </p>

                  <div className="confluences-inner_container">
                    <p className="sub-heading">Saved Confluence</p>
                    {confluencesData?.map(({ confluence, id }) => (
                      <div className="confleunce">
                        <p>{confluence}</p>
                        <p>
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => deleteConfluenceFunc(id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-2 flex-item">
                  <button
                    className="button secondary"
                    onClick={() => setOpenAddConfluenceModal(true)}
                  >
                    Add new
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfluenceModal
        state={openAddConfluenceModal}
        setState={setOpenAddConfluenceModal}
        user={user}
        createConfluence={createConfluence}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        setDataLoading={setDataLoading}
      />

      <Modal
        footer={false}
        closable={false}
        className="custom_modal"
        width="618px"
        open={openChangePasswordModal}
        onCancel={() => setOpenChangePasswordModal(false)}
      >
        <div className="settings_modal">
          <div className="top">
            <span>Change Password</span>
            <span
              className="cursor-pointer"
              onClick={() => setOpenChangePasswordModal(false)}
            >
              {closeCircle}
            </span>
          </div>
          <div className="forms">
            <PasswordInput label="Current Password" />
            <PasswordInput label="New Password" />
            <PasswordInput label="Confirm New Password" />
          </div>
          <button className="button secondary">Save Settings</button>
        </div>
      </Modal>

      {/* <Modal footer={false} closable={false} className="custom_modal" width="618px" open={openChangeEmailModal} onCancel={() => setOpenChangeEmailModal(false)}>
					<div className="settings_modal">
						{emailChangeStep !== 'done' && (
							<>
								<div className="top">
									<span>Change Password</span>
									<span className="cursor-pointer" onClick={() => setOpenChangeEmailModal(false)}>
										{closeCircle}
									</span>
								</div>
								{emailChangeStep === 'email' && (
									<>
										<div className="forms">
											<span className="info">We’ll send a verification to you new Email address</span>
											<CustomInput label="New Email" placeholder="New email address" />
										</div>
										<button className="button secondary" onClick={() => setEmailChangeStep('otp')}>
											Save Settings
										</button>
									</>
								)}
								{emailChangeStep === 'otp' && (
									<>
										<div className="forms">
											<VerificationInput
												length={5}
												placeholder=""
												// onComplete={() => setEmailChangeStep('done')}
												classNames={{
													container: 'verification_code_container',
													character: 'character',
													characterInactive: 'character--inactive',
													characterSelected: 'character--selected',
												}}
											/>
										</div>
										<button className="button secondary" onClick={() => setEmailChangeStep('done')}>
											Done
										</button>
									</>
								)}
							</>
						)}
						{emailChangeStep === 'done' && (
							<div className="email_done">
								<h4>Congratulation</h4>
								<span>Your Email Address has been successfully changed</span>
								<button className="button secondary" onClick={backToSettings}>
									Back To Settings
								</button>
							</div>
						)}
					</div>
				</Modal> */}
    </>
  );
};

export default SettingPage;
