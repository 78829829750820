import { Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { array } from 'yup';

const Infometrics = ({ trades, trackerJournalsLoading }) => {
	// const [confluenceStats, setConfluenceStats] = useState({});
	// const [recommendations, setRecommendations] = useState([]);
	const [arrayOfConfluencesStats, setArrayOfConfluencesStats] = useState([]);
	// Aggregate Trade Data

	const confluenceStats = {};
	const recommendations = [];
	// const trades = [];

	// Function to generate all possible combinations of an array
	function getCombinations(arr) {
		const result = [];
		const f = (prefix = [], arr) => {
			for (let i = 0; i < arr.length; i++) {
				result.push([...prefix, arr[i]]);
				f([...prefix, arr[i]], arr.slice(i + 1));
			}
		};
		f([], arr);
		return result;
	}

	const aggregateStats = (trades) => {
		// Aggregate stats
		// Function to create a key from confluences array
		trades.forEach((trade) => {
			const combinations = getCombinations(trade.reason);
			combinations.forEach((combination) => {
				const key = combination.sort().join(', ');
				if (!confluenceStats[key]) {
					confluenceStats[key] = {
						profit: 0,
						loss: 0,
						breakeven: 0,
						total: 0,
					};
				}
				confluenceStats[key][trade.tradeOutcome.toLowerCase()]++;
				confluenceStats[key].total++;
			});
		});
	};

	// Object.keys(confluenceStats).forEach((confluenceCombo) => {
	//   const stats = confluenceStats[confluenceCombo];
	//   const winRate = (stats.wins / stats.total) * 100;
	//   const lossRate = (stats.losses / stats.total) * 100;

	//   if (winRate < 50) {
	//     recommendations.push(
	//       `Consider reviewing the use of ${confluenceCombo} as it has a win rate of ${winRate.toFixed(
	//         2
	//       )}%.`
	//     );
	//   } else {
	//     recommendations.push(
	//       `${confluenceCombo} seems to be effective with a win rate of ${winRate.toFixed(
	//         2
	//       )}%. Keep using it!`
	//     );
	//   }
	// });

	// // console.log(recommendations);

	// Win/Loss Ratio
	// const totalTrades = trades.length;
	// const totalWins = trades.filter((trade) => trade.outcome === "win").length;
	// const totalLosses = trades.filter((trade) => trade.outcome === "loss").length;
	// const totalBreakevens = trades.filter(
	//   (trade) => trade.outcome === "breakeven"
	// ).length;

	// const winLossRatio = totalWins / (totalLosses || 1);

	// // console.log(`Total Trades: ${totalTrades}`);
	// // console.log(`Total Wins: ${totalWins}`);
	// // console.log(`Total Losses: ${totalLosses}`);
	// // console.log(`Total Breakevens: ${totalBreakevens}`);
	// // console.log(`Win/Loss Ratio: ${winLossRatio.toFixed(2)}`);

	// Most and Least Successful Confluences
	const sortedConfluences = Object.keys(confluenceStats).sort((a, b) => {
		const winRateA = confluenceStats[a].wins / confluenceStats[a].total;
		const winRateB = confluenceStats[b].wins / confluenceStats[b].total;
		return winRateB - winRateA;
	});

	// // console.log("Most Successful Confluences:", sortedConfluences.slice(0, 3));
	// // console.log("Least Successful Confluences:", sortedConfluences.slice(-3));

	// // console.log("The trades: ", trades);

	useEffect(() => {
		aggregateStats(trades);
		if (confluenceStats) {
			createArrayOfConfluencesStats();
		}
	}, []);

	const createArrayOfConfluencesStats = () => {
		let result = Object.values(confluenceStats).map((value, index) => {
			let key = Object.keys(confluenceStats)[index];
			return { confluence: key, ...value };
		});
		setArrayOfConfluencesStats(result);
	};

	const tableColums = [
		{
			title: 'Single and mixed Confluences',
			dataIndex: 'confluence',
			key: 'confluence',
		},
		{
			title: '',
			dataIndex: 'profit',
			key: 'profit',
			render: (text) => <p style={{ color: '#97F6B7' }}>{`Win: ${text}`}</p>,
		},
		{
			title: '',
			dataIndex: 'loss',
			key: 'loss',
			render: (text) => <p style={{ color: '#F85454' }}>{`Loss: ${text}`}</p>,
		},
		{
			title: '',
			dataIndex: 'breakeven',
			key: 'breakeven',
			render: (text) => <p style={{ color: '#217EEC' }}>{`Breakeven: ${text}`}</p>,
		},
		{
			title: '',
			dataIndex: 'total',
			key: 'total',
			render: (text) => `Total: ${text}`,
		},
	];

	return (
		<div>
			<Table
				scroll={{ x: 400 }}
				className="journal_table"
				loading={trackerJournalsLoading}
				dataSource={arrayOfConfluencesStats}
				columns={tableColums}
				bordered={false}
				pagination={false}
				rowKey="id"
				rowClassName="cursor-pointer"
			/>
		</div>
	);
};

export default Infometrics;
