import React, { useState } from 'react';
import TopNav from '../LandingPage/Sections/LandingNav';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './style.scss';
import CustomInput from '../../Components/forms/Input';
import TextArea from '../../Components/forms/TextArea';
import ButtonComponent from '../../Components/Button/button';
import FooterComponent from '../../Components/footer/footer';
import { GreenBlurShadow } from '../../assets/svg';

const ContactPage = () => {
	const [isSubmitting, setSubmitting] = useState(false);

	const onSubmit = () => {
		// console.log("Submit btn clicked!");
	};
	const { handleChange, handleBlur, handleSubmit, values, errors, setFieldValue, resetForm, touched } = useFormik({
		initialValues: {
			firstname: '',
			lastname: '',
			email: '',
			message: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			firstname: Yup.string().required().label('First name'),
			lastname: Yup.string().required().label('Last name'),
			email: Yup.string().email('Please type in a valid email').required().label('Email'),
			message: Yup.string().required().label('Message'),
		}),
		onSubmit,
	});

	return (
		<div className="contact_page">
			<TopNav />
			<div className="heading_box">
				<h1>Contact us</h1>
				<h2>Have a question, comment, or suggestion? We'd love to hear from you!</h2>
			</div>
			{/* <div className="green_blur_shadow">
        <GreenBlurShadow />
      </div> */}
			<div className="form_container">
				<div className="name_container">
					<CustomInput
						label="First name"
						type="text"
						className="form_item"
						placeholder="Daniel"
						onChange={handleChange('firstname')}
						errorVisible={touched['firstname']}
						errorMessage={errors['firstname']}
						onBlur={handleBlur('firstname')}
						value={values.firstname}
					/>
					<CustomInput
						label="Last name"
						type="text"
						className="form_item"
						placeholder="Don"
						onChange={handleChange('lastname')}
						errorVisible={touched['lastname']}
						errorMessage={errors['lastname']}
						onBlur={handleBlur('lastname')}
						value={values.lastname}
					/>
				</div>
				<CustomInput
					label="Email"
					type="email"
					className="form_item"
					placeholder="danieldon@gmail.com"
					onChange={handleChange('email')}
					errorVisible={touched['email']}
					errorMessage={errors['email']}
					onBlur={handleBlur('email')}
					value={values.email}
				/>
				<TextArea
					label="Message"
					className="message"
					onChange={handleChange('message')}
					errorVisible={touched['message']}
					errorMessage={errors['message']}
					onBlur={handleBlur('message')}
					value={values.message}
				/>
				<ButtonComponent buttonText="Submit" className="button" buttonWidth="100%" bgcolor="#8CFBDE" textColor="#000" loading={isSubmitting} onClick={() => handleSubmit()} />
			</div>
			<FooterComponent />
		</div>
	);
};

export default ContactPage;
