import { AnimatePresence, motion } from 'framer-motion';
import { notificationVariant } from '../../utils/helpers';
import './styles.scss';

const TextArea = ({ name, errorMessage, errorVisible, onBlur, onChange, placeholder, disabled, value, label, className, cols, rows }) => {
	return (
		<div className={`custom_input ${className || ''}`}>
			<label htmlFor="">{label}</label>
			<textarea
				className={errorVisible && errorMessage ? 'error' : ''}
				cols={cols}
				rows={rows}
				disabled={disabled}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				name={name}
			></textarea>
			<AnimatePresence>
				{errorVisible && errorMessage && (
					<motion.div variants={notificationVariant} initial="hidden" animate="visible" exit="exit" className="error_message">
						<small>{errorMessage}</small>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default TextArea;
