import React, { useState } from 'react';
import ButtonComponent from '../../Components/Button/button';
import TopNav from '../LandingPage/Sections/LandingNav';
import { CheckOutlined } from '@ant-design/icons';
import './style.scss';
import { Switch } from 'antd';
import { AnualDiscountArrow } from '../../assets/svg';
import PriceReasonCard from './reasonCard';
import FooterComponent from '../../Components/footer/footer';
import { NavLink } from 'react-router-dom';

const featureList = {
	basic: ['100% free, no commitment required', 'Limited to 5 projections at a time', 'Basic trade analysis', 'Limited support available'],
	pro: ['Unlimited Trade Journaling', 'Access to Trade tracker', 'Unlimited Projection Entries', 'Advance trade analysis included', 'Priority Support'],
};

const whyGoProArray = [
	{
		title: 'Trade Journaling',
		description: 'Never miss a trade. Track all your trades without limits and stay on top of your portfolio effortlessly.',
		color: '#FFD8BE',
	},
	{
		title: 'Advanced Analysis',
		description: 'Gain deeper insights into your trading performance. Make smarter decisions and maximize your profits.',
		color: '#9381FF',
	},
	{
		title: 'Trading Projection',
		description: 'Project your trading expectations and set reminders for key events.  Stay ahead and seize opportunities.',
		color: '#94D1BE',
	},
	{
		title: 'Trade Journaling',
		description: 'Never miss a trade. Track all your trades without limits and stay on top of your portfolio effortlessly.',
		color: '#B8B8FF',
	},
	{
		title: 'Priority Support',
		description: 'Get dedicated support when you need it. We are here for you to ensure seamless trading journey.',
		color: '#FFD8BE',
	},
];

const PricingPage = () => {
	const [isMonthly, setIsMonthly] = useState(true);

	const onPlanToggle = (checked) => {
		setIsMonthly(!isMonthly);
	};

	return (
		<div className="pricing-container">
			<TopNav />
			<div className="pricing_header_container">
				<h1>Pricing Plan</h1>
				<h2>Flexible plan that fits your trading needs</h2>
				<div className="plan_switch_container">
					<span className="monthly_text" id={isMonthly ? 'active_plan' : ''}>
						Monthly
					</span>
					<Switch onChange={onPlanToggle} />
					<span className="anual_text" id={!isMonthly ? 'active_plan' : ''}>
						Annual
					</span>
					<div className="fixed_discount_arrow">
						<AnualDiscountArrow />
					</div>
				</div>
			</div>
			<div className="plans_container">
				<div className="plan_card">
					<p className="plan_title">Trendline Trial</p>
					<p>Test and use the entire platform for free for now</p>
					<p className="plan_amount">
						<span className="currency">NGN₦</span> <span className="figure">0</span> per month
					</p>

					<ul>
						{/* {featureList.basic.map((feature) => (
              <li>
                <CheckOutlined
                  style={{
                    marginRight: "12px",
                    fontSize: "15px",
                    color: "rgba(4, 246, 181, 1)",
                  }}
                />
                {feature}
              </li>
            ))} */}
						{featureList.pro.map((feature) => (
							<li>
								<CheckOutlined
									style={{
										marginRight: '12px',
										fontSize: '15px',
										color: 'rgba(4, 246, 181, 1)',
									}}
								/>
								{feature}
							</li>
						))}
					</ul>
					<div className="btn_container" style={{ marginTop: '12%' }}>
						<NavLink to="/signup" className="navlinks">
							<ButtonComponent buttonText="Get Started for free" buttonWidth="100%" id="landing_btn" />
						</NavLink>
					</div>
				</div>
				{isMonthly ? (
					<div className="plan_card">
						<p className="plan_title">Trendline Pro</p>
						<p className="plan_amount">
							<span className="currency">NGN₦</span> <span className="figure">8000</span> per month /Billed monthly
						</p>

						<ul>
							{featureList.pro.map((feature) => (
								<li>
									<CheckOutlined
										style={{
											marginRight: '12px',
											fontSize: '15px',
											color: 'rgba(4, 246, 181, 1)',
										}}
									/>
									{feature}
								</li>
							))}
						</ul>
						<div className="btn_container" style={{ marginTop: '15%' }}>
							<NavLink to="/signup?pm=true" className="navlinks">
								<ButtonComponent buttonText="Get Started with Pro" buttonWidth="100%" bgcolor="#8CFBDE" textColor="#000" />
							</NavLink>
						</div>
					</div>
				) : (
					// This is the yearly Plan card
					<div className="plan_card">
						<p className="plan_title">Trendline Pro</p>
						<p className="plan_amount">
							<span className="currency">NGN₦</span> <span className="figure">86,400</span> per year /Billed annually (10% off)
						</p>

						<ul>
							{featureList.pro.map((feature) => (
								<li>
									<CheckOutlined
										style={{
											marginRight: '12px',
											fontSize: '15px',
											color: 'rgba(4, 246, 181, 1)',
										}}
									/>
									{feature}
								</li>
							))}
						</ul>
						<div className="btn_container" style={{ marginTop: '15%' }}>
							<ButtonComponent buttonText="Get Started with Pro" buttonWidth="100%" bgcolor="#8CFBDE" textColor="#000" />
						</div>
					</div>
				)}
			</div>
			<div className="why_go_pro_container">
				<div className="container_title">Why go Pro</div>
				<div className="inner_container">
					{whyGoProArray.map(({ title, description, color }) => (
						<PriceReasonCard title={title} description={description} tickBgColor={color} />
					))}
				</div>
			</div>
			<FooterComponent />
		</div>
	);
};

export default PricingPage;
