import { CheckCircleFilled } from "@ant-design/icons";
import React from "react";
import "./style.scss";

const PriceReasonCard = ({ title, tickBgColor, description }) => {
  return (
    <div className="price_reason_card">
      <div className="tick_icon">
        <CheckCircleFilled style={{ color: `${tickBgColor}` }} />
      </div>
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
};

export default PriceReasonCard;
