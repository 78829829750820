import { allertIconLight, trashIcon } from '../../assets/svg';
import SkeletonLoaderItem from '../skeletonLoader';
import './styles.scss';
import { Popconfirm } from 'antd';
const ProjectionCard = ({ title, description, date, onDelete, skLoading }) => {
	return (
		<div className="projection_card">
			{skLoading ? <SkeletonLoaderItem width="100px" className="mar-b-8" /> : <h6>{title}</h6>}
			{skLoading ? <SkeletonLoaderItem width="200px" className="mar-b-8" /> : <p>{description}</p>}

			<div className="card_bottom">
				{skLoading ? (
					<>
						<SkeletonLoaderItem width="70px" />
						<SkeletonLoaderItem width="30px" />
					</>
				) : (
					<>
						<div className="alert">
							<span className="icon_wrapper">{allertIconLight}</span>
							<span>{date}</span>
						</div>
						<Popconfirm title="Delete the task" description="Are you sure to delete this task?" onConfirm={() => onDelete()} okText="Yes" cancelText="No">
							<span className="cursor-pointer trash_icon">{trashIcon}</span>
						</Popconfirm>
					</>
				)}
			</div>
		</div>
	);
};

export default ProjectionCard;
