export const captureScreen = async (image, setValue) => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia();
    const video = document.createElement("video");

    video.addEventListener("loadedmetadata", () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Passing video width and height as canvas width and height.
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      video.play(); // playing video so that the image drawn won't be black.

      // Drawing an image from the captured video stream.
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      stream.getVideoTracks()[0].stop(); // ending the screensharing immediately the screenshot is taken.

      // check if an image already exists in the container
      if (image !== "") {
        setValue("imageSrc", "");
      }
      // // console.log(canvas.toDataURL());
      setValue("imageSrc", canvas.toDataURL());
    });

    video.srcObject = stream; //passing capture stream data as video source object
  } catch (err) {
    // console.log(err);
  }
};