import { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { arrowRightCircle, closeCircle2, daysDoneSvg, daysLeftSvg, daysMissedSvg } from '../../assets/svg';
import NoTradeMoalReason from './notradeModalReason';
import CreateTrackerModal from './createTrackerModal';
import { useNavigate } from 'react-router-dom';
import { getActiveTracker, updateTrackerDayStatus } from '../../firebase/firebaseFunctions';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import SkeletonLoaderItem from '../skeletonLoader';
import dayjs from 'dayjs';
import { getDayDoneMissedLeft, getTrackeCurrentrDay } from '../../utils/helpers';
import { Spin } from 'antd';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';
import { useGeneralContext } from '../../context';

const Tracker = () => {
	const navigate = useNavigate();
	const { showSuccessMessage, showErrorMessage } = useNotificationContext();

	const [activeTracker, setActiveTracker] = useState(null);
	const [showNoTradeModal, setShowNoTradeModal] = useState(false);
	const [showCreateTrackerModal, setShowCreateTrackerModal] = useState(false);
	const [dataLodaing, setDataLoading] = useState(false);
	const [updatingStatus, setUpdatingStatus] = useState(false);
	const { user } = useAuthContext();
	const { setPaymentModalVisible } = useGeneralContext();

	let currtDay = useMemo(() => {
		return getTrackeCurrentrDay(activeTracker?.dateRange, activeTracker?.weekends);
	}, [activeTracker]);

	let daysObject = useMemo(() => {
		return getDayDoneMissedLeft(activeTracker?.dateRange);
	}, [activeTracker]);

	let currntDateObject = useMemo(() => {
		return activeTracker?.dateRange?.find((v) => dayjs(v?.date).isSame(dayjs(), 'day'));
	}, [activeTracker]);

	const getActiveTrackerFunc = async () => {
		setDataLoading(true);
		try {
			const res = await getActiveTracker(user?.uid);
			setActiveTracker(res);
		} catch (error) {
		} finally {
			setDataLoading(false);
		}
	};
	const markDayAsDone = async () => {
		setUpdatingStatus(true);
		try {
			let res = await updateTrackerDayStatus({
				trackerId: activeTracker?.id,
				status: 'done',
				uniqueDateString: currntDateObject?.date,
			});
			setActiveTracker(res);
			showSuccessMessage('Tracker day updated');
		} catch (error) {
			showErrorMessage(error.message || 'An error occured');
			if (error.message === 'Error: No jornal record for today') {
				navigate('/app/journals/add');
			}
		} finally {
			setUpdatingStatus(false);
		}
	};
	const saveNoTradeReason = async (reason) => {
		let res = await updateTrackerDayStatus({
			reason,
			trackerId: activeTracker?.id,
			status: 'missed',
			uniqueDateString: currntDateObject?.date,
		});
		if (res.id) {
			setActiveTracker(res);
			showSuccessMessage('Tracker day updated');
			setShowNoTradeModal(false);
		} else {
			showErrorMessage('An error occured');
		}
	};

	const handleCreateTracker = () => {
		if (user?.paid || user.userOnFreeMode) {
			setShowCreateTrackerModal(true);
		} else {
			setPaymentModalVisible(true);
		}
	};

	useEffect(() => {
		getActiveTrackerFunc();
		// eslint-disable-next-line
	}, []);
	return (
		<>
			<div className="tracker_wrapper">
				<div className="tracker_inner">
					<span className="title">Trade Tracker</span>
					{dataLodaing ? (
						<div className="tracker_loader">
							<SkeletonLoaderItem width="300px" />
							<SkeletonLoaderItem width="300px" />
							<SkeletonLoaderItem width="300px" />
						</div>
					) : (
						<>
							{activeTracker?.id ? (
								<div className="active_tracker">
									<div className="current_day">
										<h3>{currtDay}</h3>
										<span className="tracker_range">
											{dayjs(activeTracker?.startDate).format('DD MMM')}-{dayjs(activeTracker?.endDate).format('DD MMM')}
										</span>
										<span className="days_count">{activeTracker?.dateRange?.length} days</span>
									</div>
									<div className="tracker_title" onClick={() => navigate(`/app/tracker/${activeTracker?.id}`)}>
										<span>{activeTracker?.trackerName}</span>
										{arrowRightCircle}
									</div>
									<div className="tracker_days">
										<div className="single">
											{daysDoneSvg}
											<span>{daysObject?.done} Day(s)</span>
											<span>Done</span>
										</div>
										<div className="single">
											{daysMissedSvg}
											<span>{daysObject?.missed} Day(s)</span>
											<span>Missed</span>
										</div>
										<div className="single">
											{daysLeftSvg}
											<span>{daysObject?.left} Day(s)</span>
											<span>Left</span>
										</div>
									</div>
								</div>
							) : (
								<div className="create_tracker">
									<h3>Elevate your Trading Discipline </h3>
									<button onClick={() => handleCreateTracker()}>Create Tracker</button>
								</div>
							)}
						</>
					)}
					<div className="aside_image">
						<img src="/images/tracker_img.png" alt="" />
					</div>
				</div>

				{!dataLodaing && (
					<>
						{currntDateObject?.status === '' && (
							<div className="question">
								<div className="question_text">
									<span>Did you trade today?</span>
									<br />
									<small>(This is best updated at the end of your trading day.)</small>
								</div>
								<button onClick={markDayAsDone}>{updatingStatus ? <Spin /> : 'Yes'}</button>
								<button onClick={() => setShowNoTradeModal(true)}>No</button>
								<span className="question_cancle">{closeCircle2}</span>
							</div>
						)}
					</>
				)}
			</div>
			<CreateTrackerModal onDone={getActiveTrackerFunc} visible={showCreateTrackerModal} onClose={() => setShowCreateTrackerModal(false)} />
			<NoTradeMoalReason saveReason={saveNoTradeReason} visible={showNoTradeModal} onClose={() => setShowNoTradeModal(false)} />
		</>
	);
};

export default Tracker;
