import './styles.scss';
const TradeStatus = ({ status }) => {
	if (!status) {
		return <></>;
	}
	return (
		<div className={`trade_status ${status || ''}`}>
			<div />
			<span>{status}</span>
		</div>
	);
};

export default TradeStatus;
