import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import ButtonComponent from '../../Components/Button/button';
import CustomInput from '../../Components/forms/Input';
import * as Yup from 'yup';
import { useAuthContext } from '../../context/auth-context/AuthProvider';

import './styles.scss';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';

const ForgetPassword = () => {
	const navigate = useNavigate();

	const { handleForgetPassword } = useAuthContext();
	const { showSuccessMessage } = useNotificationContext();

	const onSubmit = async (value, actions) => {
		try {
			await handleForgetPassword(value.email);
			actions.resetForm();
			showSuccessMessage('Check your email for reset link');
		} catch (error) {
			// console.log(error);
		} finally {
		}
	};

	const { handleChange, handleBlur, handleSubmit, values, errors, touched, isSubmitting } = useFormik({
		initialValues: {
			email: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			email: Yup.string().required().email().label('Email'),
		}),
		onSubmit,
	});

	const forgetPassword = async () => {
		try {
			await handleForgetPassword();
		} catch (error) {}
	};

	return (
		<div className="auth-page">
			<div className="inner-container">
				<p className="heading">Forget Password?</p>

				<span className="forget_password">We’ll send instructions to your mail</span>
				<form onSubmit={handleSubmit}>
					<div className="forms_input">
						<CustomInput
							label="Email address"
							placeholder="Enter email address"
							name="email"
							type="email"
							onChange={handleChange('email')}
							errorVisible={touched['email']}
							errorMessage={errors['email']}
							onBlur={handleBlur('email')}
							value={values.email}
						/>
					</div>
					<ButtonComponent type="submit" loading={isSubmitting} className="mar-t-24 submit_btn" buttonText="Reset Password" buttonWidth="15rem" bgcolor="#8CFBDE" />
				</form>
				<p className="alternative-option">
					Don't have an account? <NavLink to="/signup"> Create New Account</NavLink>
				</p>
			</div>
		</div>
	);
};

export default ForgetPassword;
