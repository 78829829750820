import './styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { notificationVariant } from '../../utils/helpers';

const CustomInput = ({ name, errorMessage, errorVisible, onBlur, onChange, placeholder, disabled, value, type, label, className }) => {
	return (
		<div className={`custom_input ${className || ''}`}>
			{label && <label>{label}</label>}
			<input
				className={errorVisible && errorMessage ? 'error' : ''}
				name={name}
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
				onBlur={onBlur}
			/>

			<AnimatePresence>
				{errorVisible && errorMessage && (
					<motion.div variants={notificationVariant} initial="hidden" animate="visible" exit="exit" className="error_message">
						<small>{errorMessage}</small>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default CustomInput;
