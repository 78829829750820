import React from "react";
import ButtonComponent from "../../../Components/Button/button";
import JournalSvg from "../../../assets/journalSvg";
import ProjectSvg from "../../../assets/projectSvg";
import AnalyticSvg from "../../../assets/analyticSvg";
import JournalsSvg from "../../../assets/journalsSvg";
import { NavLink } from "react-router-dom";
import TrackerSvg from "../../../assets/trackerSvg";

const WhyUs = () => {
  return (
    <div className="why-us_section">
      <p className="heading">Everything you need in one place</p>
      <p className="sub-text">
        With Spillmetrics you have all the features that empowers you to be on{" "}
        <br /> top of your game
      </p>
      <div className="reason-container">
        <div className="flex-1">
          <JournalSvg className="svg-img" />
        </div>
        <div className="flex-2">
          <p className="title">Journal Trade</p>
          <p className="description">
            Document your thoughts and insights with journal notes. Reflect on
            the emotional aspects of your trades, identify patterns, and jot
            down ideas for improvement.
          </p>
          <NavLink to="/pricing" className="navlinks">
            <ButtonComponent
              buttonText="Get Started"
              id="landing_btn"
              buttonWidth="12rem"
            />
          </NavLink>
        </div>
      </div>

      <div
        className="reason-container"
        style={{ flexDirection: "row-reverse" }}
      >
        <div className="flex-1">
          <ProjectSvg className="svg-img" />
        </div>
        <div className="flex-2">
          <p className="title">Trade Projections</p>
          <p className="description">
            create trade projections for specific period, and give us a day to
            remind you about your projection. We will send you your projection
            via notifications so you don't forget your bias or sentiment.
          </p>
          <NavLink to="/pricing" className="navlinks">
            <ButtonComponent
              buttonText="Get Started"
              id="landing_btn"
              buttonWidth="12rem"
            />
          </NavLink>
        </div>
      </div>

      <div className="reason-container">
        <div className="flex-1">
          <AnalyticSvg className="svg-img" />
        </div>
        <div className="flex-2">
          <p className="title">Advance Analytics</p>
          <p className="description">
            Uncover insights with our advanced analytics tools. From performance
            metrics and risk-reward ratios to trend analyses, our platform
            provides the tools you need to refine your trading strategies
          </p>
          <NavLink to="/pricing" className="navlinks">
            <ButtonComponent
              buttonText="Get Started"
              id="landing_btn"
              buttonWidth="12rem"
            />
          </NavLink>
        </div>
      </div>

      <div
        className="reason-container"
        style={{ flexDirection: "row-reverse" }}
      >
        <div className="flex-1">
          <TrackerSvg className="svg-img" />
        </div>
        <div className="flex-2">
          <p className="title">Trade Tracking</p>
          <p className="description">
            Join the Trade Tracker Challenge and elevate your trading game. Set
            goals, stay accountable, and unlock valuable insights. Build
            consistency, track your progress, and start your journey to trading
            mastery.
          </p>
          <NavLink to="/pricing" className="navlinks">
            <ButtonComponent
              buttonText="Get Started"
              id="landing_btn"
              buttonWidth="12rem"
            />
          </NavLink>
        </div>
      </div>

      <div className="why-container">
        <div className="heading-flex">
          <div>
            <p className="heading">Why choose Spillmetrics</p>
            <p className="sub-text">
              Discover what sets Spillmetrics apart from others in your trading{" "}
              <br />
              experience
            </p>
          </div>
          <div className="flex-2">
            <NavLink to="/pricing" className="navlinks">
              <ButtonComponent
                buttonText="Get Started"
                buttonWidth="12rem"
                bgcolor="#8CFBDE"
                textColor="#000"
              />
            </NavLink>
          </div>
        </div>
        <div className="why-flex">
          <div className="flex-1">
            <JournalsSvg className="svg-img" />
          </div>
          <div className="flex-2">
            <div>
              <p className="why-heading">I. User-Friendly Interface</p>
              <p className="why-description">
                Navigating your trading journey should be seamless. Our
                user-friendly interface allows you to effortlessly record and
                analyze your trades, providing a clear snapshot of your
                performance.
              </p>
            </div>
            <hr />
            <div>
              <p className="why-heading">II. Comprehensive Trade Entry</p>
              <p className="why-description">
                Capture every detail of your trades — stop loss and take profit,
                lot size, reason for trade, and more. Our journal ensures no
                critical aspect is overlooked, empowering you to make
                data-driven decisions
              </p>
            </div>
            <hr />
            <div>
              <p className="why-heading">III. Real-Time Performance Metrics</p>
              <p className="why-description">
                Monitor your trading performance in real-time. Track your
                win-loss ratio, average profits and losses, and other key
                metrics to understand your strengths and areas for improvement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
