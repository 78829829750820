import { collection, addDoc, query, where, getDocs, getDoc, updateDoc, doc, serverTimestamp, deleteDoc, orderBy } from "firebase/firestore"
import { db } from "./config";
import dayjs from "dayjs";
import { dateFallsInCurrentWeek, getTrackerStatus } from "../utils/helpers";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter)

export const { JOURNALS, PROJECTIONS, TRACKERS, CONFLUENCES, RECOMENDATIONS, USERS, PAYOUTACCOUNT, PAYOUTREQUEST } = {
  JOURNALS: "journals",
  PROJECTIONS: "projections",
  TRACKERS: "trackers",
  CONFLUENCES: "confluences",
  RECOMENDATIONS: "recommendations",
  USERS: "users",
  PAYOUTACCOUNT: "payoutAccounts",
  PAYOUTREQUEST: "payoutRequest"
}

export const requestPayout = async (data) => {
  let baseQuery = query(collection(db, PAYOUTREQUEST));
  baseQuery = query(baseQuery, where('userId', "==", data.userId));
  const snapshot = await getDocs(baseQuery);
  if (snapshot.empty) {
    return addDoc(collection(db, PAYOUTREQUEST), {
      ...data,
      paidOut: false,
      createdAt: serverTimestamp()
    });
  } else {
    throw new Error("You have a pending payout request")
  }
};

export const addPayoutAcount = async (data) => {
  return addDoc(collection(db, PAYOUTACCOUNT), {
    ...data,
  });
};

export const getDocuments = async (path, filters = {}) => {
  let baseQuery = query(collection(db, path));
  Object.keys(filters).forEach((filter) => {
    if (filters?.[filter] !== null) {
      if (filter !== "createdAt") {
        baseQuery = query(baseQuery, where(filter, "==", filters?.[filter]));
      } else {
        baseQuery = query(baseQuery, where(filter, '>=', dayjs(filters?.[filter][0]).startOf('day').toDate()));
        baseQuery = query(baseQuery, where(filter, '<=', dayjs(filters?.[filter][1]).endOf('day').toDate()));
      }
    }
  });
  // baseQuery = query(baseQuery, orderBy("createdAt", "desc"));
  try {
    const snapshot = await getDocs(baseQuery);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    throw (error)
  }
}

export const updateDocument = async (path, id, updateValueObject) => {
  const ref = doc(db, path, id);
  return await updateDoc(ref, {
    ...updateValueObject,
    updatedAt: serverTimestamp(),
  });
}

const getUser = async (filters) => {
  try {
    const userSnapshot = await getDocuments(USERS, filters);
    const userDetails = userSnapshot[0]
    return userDetails
  } catch (error) {

  }
};

export const getAffiliateData = async (uid, filters) => {
  let baseQuery = query(collection(db, USERS), where('refUserId', "==", uid));

  Object.keys(filters).forEach((filter) => {
    if (filters?.[filter] !== null) {
      if (filter !== "createdAt") {
        baseQuery = query(baseQuery, where(filter, "==", filters?.[filter]));
      } else {
        baseQuery = query(baseQuery, where(filter, '>=', dayjs(filters?.[filter][0]).startOf('day').toDate()));
        baseQuery = query(baseQuery, where(filter, '<=', dayjs(filters?.[filter][1]).endOf('day').toDate()));
      }
    }
  });
  baseQuery = query(baseQuery, orderBy("createdAt", "desc"));

  let currentMonthRefCount = 0;
  let proRefCount = 0;
  let currentMonthEarnings = 0
  let overallEarnings = 0
  let unpaidEarnings = 0

  try {
    const usersSnapshot = await getDocs(baseQuery);
    let users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    users.forEach((v) => {
      if (v?.createdAt) {
        const isCreatedThisMonth = dayjs().isSame(dayjs(v?.createdAt.toDate()), 'month');
        if (isCreatedThisMonth) {
          currentMonthRefCount += 1
        }
      }
      if (v?.paid) {
        proRefCount += 1
      }
    });

    return {
      currentMonthRefCount,
      totalRefCount: users.length,
      proRefCount,
      currentMonthEarnings,
      overallEarnings,
      unpaidEarnings,
      referrals: users
    }
  } catch (error) {

    // throw new (error)
  }
};

const createFirestoreUser = async (data) => {
  return addDoc(collection(db, USERS), {
    ...data,
    paid: false,
  });
};

const getDashboardData = async (userId) => {

  const allJournalQuery = query(collection(db, JOURNALS), where("userId", "==", userId));
  const recomendationsQuery = query(collection(db, RECOMENDATIONS));

  let pendingJournalQuery = query(collection(db, JOURNALS), where("userId", "==", userId));
  pendingJournalQuery = query(pendingJournalQuery, where('status', 'in', ['Pending', 'In Progress']));
  pendingJournalQuery = query(pendingJournalQuery, orderBy('timeStamp', 'desc'));

  try {

    const allJournalsQuerySnapshot = await getDocs(allJournalQuery);
    const pendingJournalQuerySnapshot = await getDocs(pendingJournalQuery);
    const recomendationsQuerySnapshot = await getDocs(recomendationsQuery);
    const projections = await getProjections(userId)

    const pendingAndInprogressJournal = pendingJournalQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    const allJournals = allJournalsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    const recommendations = recomendationsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    let total_trade_taken = {
      total: allJournals.length,
      current_week: 0,
    };
    let pair_traded = {
      total: [],
      current_week: [],
    };
    let profitable_trades = {
      total: 0,
      current_week: 0,
    };
    let loss_trades = {
      total: 0,
      current_week: 0,
    };
    let breakeven_trades = {
      total: 0,
      current_week: 0,
    };

    // let chartData = []

    allJournals.forEach((trade) => {
      // getting total vlues for card data
      if (!pair_traded.total.includes(trade.currencyPair)) {
        pair_traded.total.push(trade.currencyPair);
      }
      if (trade.tradeOutcome === "Profit") {
        profitable_trades.total += 1;
      }
      if (trade.tradeOutcome === "Loss") {
        loss_trades.total += 1;
      }
      if (trade.tradeOutcome === "Breakeven") {
        breakeven_trades.total += 1;
      }

      // getting weekly values for chart data
      if (dateFallsInCurrentWeek(dayjs(trade?.timeStamp?.toDate()))) {
        total_trade_taken.current_week += 1;
        if (!pair_traded.current_week.includes(trade.currencyPair)) {
          pair_traded.current_week.push(trade.currencyPair);
        }
        if (trade.tradeOutcome === "Profit") {
          profitable_trades.current_week += 1;
        }
        if (trade.tradeOutcome === "Loss") {
          loss_trades.current_week += 1;
        }
        if (trade.tradeOutcome === "Breakeven") {
          breakeven_trades.current_week += 1;
        }
      }

      // getting chart data
      // let tradeDate =
      // chartData.push({
      //   name:
      // })
    });
    // let profitable_trades_percentage = ((Number(profitable_trades) * 100) / Number(total_trade_taken)).toFixed(1)
    // let loss_trades_percentage = ((Number(loss_trades) * 100) / Number(total_trade_taken)).toFixed(1)
    // let breakeven_trades_percentage = ((Number(breakeven_trades) * 100) / Number(total_trade_taken)).toFixed(1)

    return {
      total_trade_taken,
      pair_traded: {
        total: pair_traded.total.length,
        current_week: pair_traded.current_week.length,
      },
      profitable_trades,
      breakeven_trades,
      loss_trades,
      pendingAndInprogressJournal,
      recommendations,
      projections
    }


  } catch (error) {
    // console.log(error);
  }
}

// We will be using the userId as a foreignKey to associate the user to their created items.
const createJournal = async ({
  imageSrc,
  currencyPair,
  orderType,
  entryPrice,
  lotSize,
  stopLoss,
  takeProfit,
  status,
  reason,
  generalComment,
  tradeOutcome,
  trackerId,
  userId,
}) => {
  return addDoc(collection(db, JOURNALS), {
    imageSrc,
    currencyPair,
    orderType,
    entryPrice,
    lotSize,
    stopLoss,
    takeProfit,
    status,
    tradeOutcome,
    reason,
    generalComment,
    userId,
    trackerId,
    timeStamp: serverTimestamp(),
  });
};

const getJournals = async (userId, filters = {}) => {
  let baseQuery = query(
    collection(db, JOURNALS),
    where("userId", "==", userId)
  );


  Object.keys(filters).forEach((filter) => {
    if (filters?.[filter]) {
      if (filter !== "timeStamp") {
        baseQuery = query(baseQuery, where(filter, "==", filters?.[filter]));
      } else {
        baseQuery = query(baseQuery, where(filter, '>=', dayjs(filters?.[filter][0]).startOf('day').toDate()));
        baseQuery = query(baseQuery, where(filter, '<=', dayjs(filters?.[filter][1]).endOf('day').toDate()));
      }
    }
  });
  baseQuery = query(baseQuery, orderBy("timeStamp", "desc"));

  try {
    const querySnapshot = await getDocs(baseQuery);
    const allJournals = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return allJournals;
  } catch (error) {
    throw new Error(error);
  }
};

const getPairSummary = async (userId, filters = {}) => {
  let allJournals = await getJournals(userId, filters)


  let total_trade_taken = allJournals.length
  let profitable_trades = 0
  let loss_trades = 0
  let breakeven_trades = 0

  allJournals.forEach((trade) => {
    if (trade.tradeOutcome === "Profit") {
      profitable_trades += 1;
    }
    if (trade.tradeOutcome === "Loss") {
      loss_trades += 1;
    }
    if (trade.tradeOutcome === "Breakeven") {
      breakeven_trades += 1;
    }
  });

  return {
    total_trade_taken,
    profitable_trades,
    breakeven_trades,
    loss_trades,
  }
}

const getSingleJournal = async (id) => {
  const docRef = doc(db, JOURNALS, id);
  try {
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (error) {
    return error;
  }
};


const deleteJournal = async (id) => {
  try {
    await deleteDoc(doc(db, JOURNALS, id));
  } catch (e) {
    // console.log("An error occured while deleting: ", e);
  }
  return;
};

const createProjection = async ({
  subject,
  projection,
  reminderDate,
  reminderTime,
  status,
  userId,
}) => {
  try {
    const docRef = await addDoc(collection(db, PROJECTIONS), {
      subject,
      projection,
      reminderDate,
      reminderTime,
      status,
      userId,
      timeStamp: serverTimestamp(),
    });
    return docRef.id;
  } catch (e) {
    // console.log("error adding new projection: ", e);
  }
  return;
};
const getProjections = async (userId) => {
  const q = query(collection(db, PROJECTIONS), where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  const allProjections = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return allProjections;
};
const deleteProjection = async (id) => {
  try {
    await deleteDoc(doc(db, PROJECTIONS, id));
  } catch (e) {
    // console.log("An error occured while deleting: ", e);
  }
  return;
};

const createConfluence = async ({ confluence, userId }) => {
  try {
    await addDoc(collection(db, CONFLUENCES), {
      confluence,
      userId,
      timeStamp: serverTimestamp(),
    });
    return;
  } catch (e) {
    // console.log("error adding new confluence: ", e);
  }
  return;
};

const getConfluences = async (userId) => {
  const q = query(collection(db, CONFLUENCES), where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  const allConfluences = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return allConfluences;
};

const deleteConfluence = async (id) => {
  try {
    await deleteDoc(doc(db, CONFLUENCES, id));
  } catch (e) {
    // console.log("An error occured while deleting: ", e);
  }
  return;
};
const createTrackerFunc = async ({ trackerName, noOfDays, startDate, description, weekends, dateRange, userId }) => {
  try {
    const docRef = await addDoc(collection(db, TRACKERS), {
      trackerName,
      noOfDays,
      startDate,
      endDate: dateRange[dateRange.length - 1].date,
      dateRange,
      description,
      weekends,
      userId,
      timeStamp: serverTimestamp(),
    });
    return docRef
  } catch (e) {
    // console.log("error adding new tracker: ", e);
  }
}
const getTrackerDisabledDate = async (userId) => {
  if (!userId) return
  let baseQuery = query(collection(db, TRACKERS), where("userId", "==", userId));
  baseQuery = query(baseQuery, orderBy('timeStamp', 'asc'));
  try {
    const dates = []
    const querySnapshot = await getDocs(baseQuery);
    querySnapshot.docs.forEach(doc => {
      if (dayjs(doc.data()?.endDate).isSameOrAfter(dayjs(), 'day')) {
        doc.data()?.dateRange?.forEach(v => {
          if (!dates.includes(v?.date)) {
            dates.push(v?.date)
          }
        })
      }
    })
    return dates
  } catch (error) {
    // console.log(error);
    return error
  }
}
const getTrackerList = async (userId) => {
  if (!userId) return
  let baseQuery = query(collection(db, TRACKERS), where("userId", "==", userId));
  baseQuery = query(baseQuery, orderBy('timeStamp', 'asc'));

  try {
    const querySnapshot = await getDocs(baseQuery);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    // console.log(error);
    return error
  }
}
const deleteTracker = async (trackerId) => {
  if (!trackerId) return
  try {
    await deleteDoc(doc(db, TRACKERS, trackerId));
  } catch (error) {
    // console.log(error);
  }
}
const getActiveTracker = async (userId, endDate) => {
  if (!userId) return

  let baseQuery = query(collection(db, TRACKERS), where("userId", "==", userId));
  baseQuery = query(baseQuery, where('endDate', '>=', dayjs(endDate).format("YYYY-MM-DD")));

  let activeTracker = null
  try {
    const querySnapshot = await getDocs(baseQuery);
    querySnapshot.docs.some(doc => {
      let status = getTrackerStatus(doc.data()?.startDate, doc.data()?.endDate);
      if (status === 'ongoing') {
        activeTracker = { id: doc.id, ...doc.data() }
        return true
      }
    })
    return activeTracker
  } catch (error) {
    // console.log(error);
    return error
  }
}
const updateTrackerDayStatus = async ({ trackerId, uniqueDateString, status, reason }) => {
  try {
    const docRef = doc(db, TRACKERS, trackerId);
    const docSnapshot = await getDoc(docRef);


    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      let index = Array.from(data?.dateRange).findIndex(v => dayjs(v?.date).isSame(dayjs(uniqueDateString)))
      const newDateRangeArr = [...data['dateRange']]

      if (status) {

        if (status === 'done') {
          let date = dayjs()
          let res = await getJournals(data.userId, { timeStamp: [date, date] })
          if (Array.isArray(res) && res.length < 1) throw new Error("No jornal record for today")
        }
        newDateRangeArr[index].status = status
      }
      if (reason) {
        newDateRangeArr[index].reasonMissed = reason
      }

      if (index >= 0) {

        await updateDoc(docRef, {
          dateRange: newDateRangeArr
        });
        return { ...data, dateRange: newDateRangeArr, id: trackerId }

      } else {
        throw new Error("Invalid array name or index out of bounds.")
      }
    } else {
      throw new Error("Document does not exist.")
    }
  } catch (error) {
    throw new Error(error);
  }
}
const getTrackerDetetails = async (trackerId) => {
  if (!trackerId) return

  try {
    // Retrieve the document
    const docRef = doc(db, TRACKERS, trackerId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return { ...data, id: trackerId }
    }
    throw new Error("Tracker not found")

  } catch (error) {
    throw new Error(error)
  }
}

export {
  createFirestoreUser,
  createJournal,
  getJournals,
  getSingleJournal,
  deleteJournal,
  createProjection,
  getProjections,
  deleteProjection,
  getDashboardData,
  createTrackerFunc,
  getTrackerDisabledDate,
  getTrackerList,
  deleteTracker,
  getActiveTracker,
  updateTrackerDayStatus,
  getTrackerDetetails,
  createConfluence,
  getConfluences,
  deleteConfluence,
  getPairSummary,
  getUser
};
