import React from "react";
import { NavLink } from "react-router-dom";
import ButtonComponent from "../Button/button";
import "./style.scss";

const FooterComponent = () => {
  return (
    <div className="footer_wrapper">
      <footer>
        <p>Your trading success story begins here</p>
        <NavLink to="/pricing" className="navlinks">
          <ButtonComponent
            buttonText="Get Started"
            buttonWidth="12rem"
            bgcolor="#8CFBDE"
            textColor="#000"
          />
        </NavLink>
      </footer>
    </div>
  );
};

export default FooterComponent;
